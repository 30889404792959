<template>
    <div class="container">
        <div class="title-section">
            <div class="title-content">
                <h2>Configuration GTA RP</h2>
                <p class="description">Configurez les paramètres de détection pour le mode RP de GTA</p>
            </div>
            <div class="title-controls">
                <div class="toggle-container">
                    <label class="toggle">
                        <input type="checkbox" v-model="isModuleEnabled" @change="handleModuleToggle">
                        <span class="slider"></span>
                    </label>
                    <span class="toggle-label" :class="{ 'active': isModuleEnabled }">{{ isModuleEnabled ? 'Activé' : 'Désactivé' }}</span>
                </div>
                <div class="title-icon">
                    🎮
                </div>
            </div>
        </div>
        
        <div v-if="isModuleEnabled">
            <div class="form-section">
                <h3>Serveur RP</h3>
                <p class="section-description">Sélectionnez votre serveur RP pour une meilleure détection</p>
                <div class="input-container">
                    <select v-model="serverCode" class="server-select">
                        <option v-for="server in serverCodes" :key="server.Code" :value="server.Code">
                            {{ server.Name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-section">
                <h3>Salons Discord</h3>
                <p class="section-description">Configurez les salons pour les notifications</p>
                
                <div class="channel-section">
                    <h4>
                        <img src="../assets/discord-mark-white.svg" alt="Discord" class="discord-icon" />
                        Salon HRP
                    </h4>
                    <div class="channel-selector">
                        <div class="radio-group">
                            <label class="radio-label">
                                <input type="radio" v-model="hrpChannelType" value="general">
                                <span>Utiliser le salon général</span>
                            </label>
                            <label class="radio-label">
                                <input type="radio" v-model="hrpChannelType" value="custom">
                                <span>Spécifier un autre salon</span>
                            </label>
                        </div>
                        <div v-if="hrpChannelType === 'custom'" class="custom-channel">
                            <div class="channel-input-container">
                                <span class="channel-hash">#</span>
                                <input 
                                    type="text" 
                                    v-model="hrpChannelId" 
                                    placeholder="ID du salon Discord"
                                    class="channel-input"
                                />
                            </div>
                            <p class="helper-text">L'ID du salon peut être obtenu en faisant un clic droit sur le salon et en sélectionnant "Copier l'identifiant"</p>
                        </div>
                    </div>
                </div>

                <div class="channel-section">
                    <h4>
                        <img src="../assets/discord-mark-white.svg" alt="Discord" class="discord-icon" />
                        Salon StreamHack
                    </h4>
                    <div class="channel-selector">
                        <div class="radio-group">
                            <label class="radio-label">
                                <input type="radio" v-model="shChannelType" value="general">
                                <span>Utiliser le salon général</span>
                            </label>
                            <label class="radio-label">
                                <input type="radio" v-model="shChannelType" value="custom">
                                <span>Spécifier un autre salon</span>
                            </label>
                        </div>
                        <div v-if="shChannelType === 'custom'" class="custom-channel">
                            <div class="channel-input-container">
                                <span class="channel-hash">#</span>
                                <input 
                                    type="text" 
                                    v-model="shChannelId" 
                                    placeholder="ID du salon Discord"
                                    class="channel-input"
                                />
                            </div>
                            <p class="helper-text">L'ID du salon peut être obtenu en faisant un clic droit sur le salon et en sélectionnant "Copier l'identifiant"</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-section">
                <div class="section-header">
                    <h3>Mots-clés de base</h3>
                    <span class="keyword-limit">({{ basicKeywords.length }}/4)</span>
                </div>
                <p class="section-description">Ajoutez jusqu'à 4 mots-clés pour la détection de base</p>
                
                <div class="keywords-list">
                    <div v-for="(keyword, index) in basicKeywords" :key="index" class="keyword-item">
                        <input 
                            type="text" 
                            v-model="keyword.Value" 
                            placeholder="Entrez un mot-clé"
                            class="channel-input"
                        />
                        <select v-model="keyword.Type" class="server-select">
                            <option value="FULL">Mot complet</option>
                            <option value="PART">Partie de mot</option>
                        </select>
                        <button @click="removeKeyword(index, 'basic')" class="remove-btn" title="Supprimer">
                            ✕
                        </button>
                    </div>
                </div>
                <button 
                    @click="addKeyword('basic')" 
                    class="save-button" 
                    :disabled="basicKeywords.length >= 4"
                >
                    + Ajouter un mot-clé
                </button>
            </div>

            <div v-if="isPremium" class="form-section premium-section">
                <div class="section-header">
                    <div class="header-title">
                        <h3>Options Premium</h3>
                        <span class="premium-badge">
                            <span class="premium-icon">⭐</span>
                            Premium
                        </span>
                    </div>
                </div>

                <div class="premium-content">
                    <div class="keywords-section">
                        <h4>Mots-clés Premium</h4>
                        <div class="keywords-list">
                            <div v-for="(keyword, index) in premiumKeywords" :key="index" class="keyword-item">
                                <input 
                                    type="text" 
                                    v-model="keyword.Value" 
                                    placeholder="Entrez un mot-clé premium"
                                    class="channel-input"
                                />
                                <select v-model="keyword.Type" class="server-select">
                                    <option value="FULL">Mot complet</option>
                                    <option value="PART">Partie de mot</option>
                                </select>
                                <button @click="removeKeyword(index, 'premium')" class="remove-btn" title="Supprimer">
                                    ✕
                                </button>
                            </div>
                        </div>
                        <button @click="addKeyword('premium')" class="save-button">
                            + Ajouter un mot-clé premium
                        </button>
                    </div>

                    <div class="advanced-toggle">
                        <button @click="showAdvancedOptions = !showAdvancedOptions" class="toggle-advanced-btn">
                            <span class="toggle-icon">{{ showAdvancedOptions ? '▼' : '▶' }}</span>
                            {{ showAdvancedOptions ? 'Masquer les options avancées' : 'Afficher les options avancées' }}
                        </button>
                    </div>

                    <div v-if="showAdvancedOptions" class="advanced-options">
                        <div class="list-section">
                            <h4>Comptes à surveiller</h4>
                            <div class="list-container">
                                <div v-for="(person, index) in flaggedPersons" :key="index" class="list-item">
                                    <input 
                                        type="text" 
                                        v-model="flaggedPersons[index]" 
                                        placeholder="Pseudo Twitch"
                                        class="channel-input"
                                    />
                                    <button @click="removePerson(index, 'flagged')" class="remove-btn">✕</button>
                                </div>
                                <button @click="addPerson('flagged')" class="add-btn">+ Ajouter un compte</button>
                            </div>
                        </div>

                        <div class="list-section">
                            <h4>Amis</h4>
                            <p class="section-description">Ces comptes ne seront pas inclus dans les logs</p>
                            <div class="list-container">
                                <div v-for="(friend, index) in friends" :key="index" class="list-item">
                                    <input 
                                        type="text" 
                                        v-model="friends[index]" 
                                        placeholder="Pseudo Twitch"
                                        class="channel-input"
                                    />
                                    <button @click="removePerson(index, 'friends')" class="remove-btn">✕</button>
                                </div>
                                <button @click="addPerson('friends')" class="add-btn">+ Ajouter un ami</button>
                            </div>
                        </div>

                        <div class="list-section">
                            <h4>Comptes ignorés</h4>
                            <p class="section-description">Ces comptes seront ignorés dans les logs StreamHack</p>
                            <div class="list-container">
                                <div v-for="(person, index) in ignoredPersons" :key="index" class="list-item">
                                    <input 
                                        type="text" 
                                        v-model="ignoredPersons[index]" 
                                        placeholder="Pseudo Twitch"
                                        class="channel-input"
                                    />
                                    <button @click="removePerson(index, 'ignored')" class="remove-btn">✕</button>
                                </div>
                                <button @click="addPerson('ignored')" class="add-btn">+ Ajouter un compte</button>
                            </div>
                        </div>

                        <div class="list-section">
                            <h4>Mots à ignorer</h4>
                            <p class="section-description">Ces mots seront ignorés dans les logs HRP</p>
                            <div class="list-container">
                                <div v-for="(word, index) in stringsToIgnore" :key="index" class="list-item">
                                    <input 
                                        type="text" 
                                        v-model="stringsToIgnore[index]" 
                                        placeholder="Mot à ignorer"
                                        class="channel-input"
                                    />
                                    <button @click="removeItem(index, 'strings')" class="remove-btn">✕</button>
                                </div>
                                <button @click="addItem('strings')" class="add-btn">+ Ajouter un mot</button>
                            </div>
                        </div>

                        <div class="list-section">
                            <h4>Chaînes ignorées</h4>
                            <p class="section-description">Ces chaînes Twitch ne seront pas surveillées</p>
                            <div class="list-container">
                                <div v-for="(channel, index) in channelsToIgnore" :key="index" class="list-item">
                                    <input 
                                        type="text" 
                                        v-model="channelsToIgnore[index]" 
                                        placeholder="Chaîne Twitch"
                                        class="channel-input"
                                    />
                                    <button @click="removeItem(index, 'channels')" class="remove-btn">✕</button>
                                </div>
                                <button @click="addItem('channels')" class="add-btn">+ Ajouter une chaîne</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="form-section premium-section premium-locked">
                <div class="section-header">
                    <h3>
                        Options Premium 
                        <span class="premium-badge">
                            <span class="premium-icon">⭐</span>
                            Premium
                        </span>
                    </h3>
                </div>
                <div class="premium-promo">
                    <p class="premium-promo-text">
                        Débloquez des options avancées et améliorez votre expérience RP avec un compte Premium !
                    </p>
                    <router-link to="/premium" class="premium-cta-btn">
                        Passer en Premium
                    </router-link>
                </div>
            </div>

            <div class="actions-section">
                <button @click="saveChanges" class="save-button" :disabled="isSaving">
                    {{ isSaving ? 'Enregistrement...' : 'Enregistrer les modifications' }}
                </button>
            </div>
        </div>
        <div v-else class="module-disabled">
            <p>Activez le module pour accéder aux paramètres de configuration</p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useSubscriptionData } from '../composables/useSubscriptionData'
import { notifications } from '../store/notifications'
import { api } from '../services/api'
import { logger } from '../utils/logger'

export default {
    name: 'RolePlay',
    setup() {
        const { subscriptionData, error, fetchSubscriptionData } = useSubscriptionData()
        const isModuleEnabled = ref(false)
        const isPremium = ref(false)
        const serverCode = ref('')
        const serverCodes = ref([])
        const hrpChannelType = ref('general')
        const hrpChannelId = ref('')
        const shChannelType = ref('general')
        const shChannelId = ref('')
        const showAdvancedOptions = ref(false)
        const premiumKeywords = ref([])
        const flaggedPersons = ref([])
        const channelsToIgnore = ref([])
        const basicKeywords = ref([])
        const friends = ref([])
        const ignoredPersons = ref([])
        const stringsToIgnore = ref([])
        const isSaving = ref(false)

        // Fonction pour mettre à jour les données locales
        const updateLocalData = (data) => {
            logger.log('Updating RolePlay data with:', data)
            
            try {
                // Parse data if it's a string
                const parsedData = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
                
                // Chercher le module RolePlay (ModuleType: 101) dans BotModules
                const rolePlayData = parsedData?.BotModules?.find(m => m.ModuleType === 101)
                
                if (!rolePlayData) {
                    logger.warn('No roleplay module data found')
                    return
                }

                isModuleEnabled.value = rolePlayData.IsActive || false
                isPremium.value = parsedData?.PremiumStatus === 1 || parsedData?.IsVIP === true

                // Update server codes list and selected server
                if (rolePlayData.RpServerCodes && Array.isArray(rolePlayData.RpServerCodes)) {
                    serverCodes.value = rolePlayData.RpServerCodes
                }
                serverCode.value = rolePlayData.RpServerCode || ''

                // Configuration des canaux
                if (rolePlayData.HRPDiscordChannelId) {
                    hrpChannelType.value = 'custom'
                    hrpChannelId.value = rolePlayData.HRPDiscordChannelId
                } else {
                    hrpChannelType.value = 'general'
                    hrpChannelId.value = ''
                }

                if (rolePlayData.SHDiscordChannelId) {
                    shChannelType.value = 'custom'
                    shChannelId.value = rolePlayData.SHDiscordChannelId
                } else {
                    shChannelType.value = 'general'
                    shChannelId.value = ''
                }

                // Mots-clés
                basicKeywords.value = rolePlayData.BasicKeywords || []
                premiumKeywords.value = rolePlayData.PremiumKeywords || []
                ignoredPersons.value = rolePlayData.IgnoredPersons || []
                stringsToIgnore.value = rolePlayData.StringsToIgnore || []
                channelsToIgnore.value = rolePlayData.ChannelsToIgnore || []
                flaggedPersons.value = rolePlayData.FlaggedPersons || []
                friends.value = rolePlayData.Friends || []

            } catch (error) {
                logger.error('Error parsing subscription data:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du chargement des données',
                    timeout: 5000
                })
            }
        }

        // Charger les données au montage du composant
        onMounted(async () => {
            // Essayer de charger depuis le cache d'abord
            const cachedData = localStorage.getItem('subscription_info')
            if (cachedData) {
                try {
                    const parsedData = JSON.parse(cachedData)
                    updateLocalData(parsedData)
                } catch (e) {
                    logger.error('Error parsing cached data:', e)
                }
            }
            
            // Puis charger les données fraîches
            await fetchSubscriptionData()
        })

        // Surveiller les changements dans les données d'abonnement
        watch(subscriptionData, (newData) => {
            if (newData) {
                updateLocalData(newData)
            }
        }, { immediate: true })

        // Surveiller les erreurs
        watch(error, (newError) => {
            if (newError) {
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du chargement des données: ' + newError,
                    timeout: 5000
                })
            }
        })

        const handleModuleToggle = async () => {
            isSaving.value = true
            try {
                const response = await api.post('/subscriptions/modules', {
                    moduleType: 101,
                    isActive: isModuleEnabled.value,
                    rpServerCode: serverCode.value,
                    hrpDiscordChannelId: hrpChannelType.value === 'custom' ? hrpChannelId.value : null,
                    shDiscordChannelId: shChannelType.value === 'custom' ? shChannelId.value : null,
                    basicKeywords: basicKeywords.value,
                    premiumKeywords: premiumKeywords.value,
                    flaggedPersons: flaggedPersons.value,
                    friends: friends.value,
                    ignoredPersons: ignoredPersons.value,
                    stringsToIgnore: stringsToIgnore.value,
                    channelsToIgnore: channelsToIgnore.value
                })

                if (response.data.success) {
                    notifications.add({
                        type: 'success',
                        message: `Module ${isModuleEnabled.value ? 'activé' : 'désactivé'}`,
                        timeout: 5000
                    })
                    // Refresh subscription data to get updated state
                    await fetchSubscriptionData()
                }
            } catch (error) {
                logger.error('Error toggling module:', error)
                isModuleEnabled.value = !isModuleEnabled.value // Revert the change
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la mise à jour du module',
                    timeout: 5000
                })
            } finally {
                isSaving.value = false
            }
        }

        const saveChanges = async () => {
            isSaving.value = true
            try {
                const response = await api.post('/subscriptions/modules', {
                    moduleType: 101,
                    isActive: isModuleEnabled.value,
                    rpServerCode: serverCode.value,
                    hrpDiscordChannelId: hrpChannelType.value === 'custom' ? hrpChannelId.value : null,
                    shDiscordChannelId: shChannelType.value === 'custom' ? shChannelId.value : null,
                    basicKeywords: basicKeywords.value,
                    premiumKeywords: premiumKeywords.value,
                    flaggedPersons: flaggedPersons.value,
                    friends: friends.value,
                    ignoredPersons: ignoredPersons.value,
                    stringsToIgnore: stringsToIgnore.value,
                    channelsToIgnore: channelsToIgnore.value
                })

                if (response.data.success) {
                    notifications.add({
                        type: 'success',
                        message: 'Configuration sauvegardée',
                        timeout: 5000
                    })
                    // Refresh subscription data to get updated state
                    await fetchSubscriptionData()
                }
            } catch (error) {
                logger.error('Error saving changes:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la sauvegarde',
                    timeout: 5000
                })
            } finally {
                isSaving.value = false
            }
        }

        const addKeyword = (type) => {
            const newKeyword = {
                Value: '',
                Type: 'FULL'
            }
            
            if (type === 'basic') {
                if (basicKeywords.value.length < 4) {
                    basicKeywords.value.push(newKeyword)
                }
            } else if (type === 'premium') {
                premiumKeywords.value.push(newKeyword)
            }
        }

        const removeKeyword = (index, type) => {
            if (type === 'basic') {
                basicKeywords.value.splice(index, 1)
            } else if (type === 'premium') {
                premiumKeywords.value.splice(index, 1)
            }
        }

        const addPerson = (type) => {
            if (type === 'flagged') {
                flaggedPersons.value.push('')
            } else if (type === 'friends') {
                friends.value.push('')
            } else if (type === 'ignored') {
                ignoredPersons.value.push('')
            }
        }

        const removePerson = (index, type) => {
            if (type === 'flagged') {
                flaggedPersons.value.splice(index, 1)
            } else if (type === 'friends') {
                friends.value.splice(index, 1)
            } else if (type === 'ignored') {
                ignoredPersons.value.splice(index, 1)
            }
        }

        return {
            isModuleEnabled,
            isPremium,
            serverCode,
            serverCodes,
            hrpChannelType,
            hrpChannelId,
            shChannelType,
            shChannelId,
            showAdvancedOptions,
            basicKeywords,
            premiumKeywords,
            flaggedPersons,
            friends,
            ignoredPersons,
            stringsToIgnore,
            channelsToIgnore,
            isSaving,
            handleModuleToggle,
            saveChanges,
            addKeyword,
            removeKeyword,
            addPerson,
            removePerson
        }
    }
}
</script>

<style scoped>
.container {
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
}

.title-section {
    background: #1A202C;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #2D3748;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title-content h2 {
    color: white;
    margin: 0 0 0.5rem 0;
    font-size: 1.8rem;
    font-weight: 600;
}

.title-content .description {
    color: #A0AEC0;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
}

.title-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 200px;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 140px;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    flex-shrink: 0;
}

.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2D3748;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #004B55;
}

input:checked + .slider:before {
    transform: translateX(24px);
}

.toggle-label {
    color: #A0AEC0;
    font-size: 0.9rem;
    font-weight: 500;
    min-width: 70px;
    text-align: left;
}

.toggle-label.active {
    color: white;
}

.title-icon {
    font-size: 1.5rem;
    margin-left: 0.5rem;
    flex-shrink: 0;
}

.form-section {
    background: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-section h3 {
    color: white;
    margin: 0 0 1rem 0;
    font-size: 1.3rem;
}

.section-description {
    color: #A0AEC0;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
}

.input-container {
    margin-top: 1rem;
}

.server-select {
    width: 100%;
    padding: 0.8rem 1rem;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    padding-right: 2.5rem;
}

.server-select option {
    background: #2D3748;
    color: white;
}

.channel-input {
    width: 100%;
    padding: 0.8rem 1rem;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.channel-input::placeholder {
    color: #666;
}

.server-select:hover, .channel-input:hover {
    border-color: #4a4a4a;
}

.server-select:focus, .channel-input:focus {
    outline: none;
    border-color: #004B55;
    background-color: #2f2f2f;
}

.helper-text {
    color: #A0AEC0;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    font-style: italic;
}

.save-button {
    background-color: #004B55;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.save-button:hover {
    background-color: #00596B;
}

.save-button:disabled {
    background-color: #2D3748;
    cursor: not-allowed;
    opacity: 0.7;
}

.status-message {
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 8px;
    font-size: 0.9rem;
}

.status-message.success {
    background-color: #004B55;
    color: white;
}

.status-message.error {
    background-color: #E53E3E;
    color: white;
}

.keywords-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.keyword-item {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.keyword-item .channel-input {
    flex: 2;
}

.keyword-item .server-select {
    flex: 1;
    min-width: 140px;
}

.remove-btn {
    background: none;
    border: none;
    color: #ff4444;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    line-height: 1;
    font-size: 1.2rem;
    font-weight: bold;
}

.remove-btn:hover {
    background: rgba(255, 68, 68, 0.1);
}

.premium-section {
    border: 1px solid rgba(255, 215, 0, 0.2);
    background: linear-gradient(to right bottom, rgba(42, 42, 42, 1), rgba(42, 42, 42, 0.95));
}

.section-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.premium-badge {
    display: inline-flex;
    align-items: center;
    background: linear-gradient(135deg, #FFD700, #FFA500);
    color: #1a1a1a;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.premium-icon {
    margin-right: 0.4rem;
    font-size: 0.9rem;
}

.module-disabled {
    text-align: center;
    padding: 2rem;
    background: #1A202C;
    border-radius: 12px;
    margin-top: 1rem;
    border: 1px solid #2D3748;
    color: #A0AEC0;
}

.input-group {
    margin-bottom: 1rem;
}

.input-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #A0AEC0;
}

.advanced-options {
    margin-top: 2rem;
    padding: 1rem;
    background: rgba(45, 55, 72, 0.3);
    border-radius: 8px;
}

.list-section {
    margin-bottom: 2rem;
}

.list-section h4 {
    color: #E2E8F0;
    margin-bottom: 0.5rem;
}

.list-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.list-item {
    display: flex;
    gap: 0.5rem;
}

.toggle-advanced-btn {
    background: none;
    border: 1px solid #4A5568;
    color: #A0AEC0;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.toggle-advanced-btn:hover {
    background: #2D3748;
    color: white;
}

.add-btn {
    background: none;
    border: 1px dashed #4A5568;
    color: #A0AEC0;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s;
}

.add-btn:hover {
    background: #2D3748;
    color: white;
}

.channel-section {
    margin-bottom: 2rem;
}

.channel-section h4 {
    color: #E2E8F0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.channel-section h4 img {
    width: 20px;
    height: 20px;
    filter: brightness(0) invert(1);
}

.channel-selector {
    background: rgba(45, 55, 72, 0.3);
    border-radius: 8px;
    padding: 1.25rem;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.25rem;
}

.radio-label {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    color: #A0AEC0;
    font-size: 1rem;
    transition: color 0.2s;
}

.radio-label:hover {
    color: #E2E8F0;
}

.radio-label input[type="radio"] {
    margin: 0;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
}

.custom-channel {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid #2D3748;
}

.helper-text {
    font-size: 0.875rem;
    color: #718096;
    margin-top: 0.75rem;
    line-height: 1.4;
}

.channel-input-container {
    display: flex;
    align-items: center;
    background: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 6px;
    padding: 0 0.75rem;
    transition: all 0.2s;
}

.channel-input-container:focus-within {
    border-color: #4A5568;
    box-shadow: 0 0 0 1px rgba(74, 85, 104, 0.2);
}

.channel-hash {
    color: #718096;
    font-size: 1.25rem;
    font-weight: bold;
    padding-right: 0.5rem;
}

.channel-input {
    flex: 1;
    background: transparent;
    border: none;
    color: #E2E8F0;
    padding: 0.75rem 0;
    font-size: 1rem;
    outline: none;
}

.channel-input::placeholder {
    color: #4A5568;
}
</style>

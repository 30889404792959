<template>
  <div id="app">
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
      <p>Chargement...</p>
    </div>
    <router-view v-else />
    <NotificationList />
  </div>
</template>

<script>
import NotificationList from './components/NotificationList.vue'
import { ref, onMounted } from 'vue'
import { useSubscriptionData } from './composables/useSubscriptionData'
import { authService } from './services/auth'

export default {
  name: 'App',
  components: {
    NotificationList
  },
  setup() {
    const isLoading = ref(true)
    const { fetchSubscriptionData } = useSubscriptionData()

    onMounted(async () => {
      try {
        // Charger les données utilisateur Discord en premier
        await authService.getUserInfo()
        // Puis charger les données d'abonnement
        await fetchSubscriptionData()
      } catch (err) {
        console.error('Error loading initial data:', err)
      } finally {
        isLoading.value = false
      }
    })

    return {
      isLoading
    }
  }
}
</script>

<style>
#app {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #141821;
  min-height: 100vh;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #141821;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #2D3748;
  border-top: 3px solid #004B55;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.loading-overlay p {
  color: #A0AEC0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

import { ref, onMounted, watch } from 'vue'
import { subscriptionService } from '../services/subscription'
import { impersonationStore } from '../store/impersonation'

export function useSubscriptionData() {
    const subscriptionData = ref(null)
    const error = ref(null)
    const loading = ref(false)

    const parseSubscriptionData = (data) => {
        if (!data) return null
        try {
            // Si les données sont une chaîne JSON, les parser
            const parsedData = typeof data === 'string' ? JSON.parse(data) : data
            
            // Si data.data est une chaîne JSON, la parser également
            if (typeof parsedData.data === 'string') {
                parsedData.data = JSON.parse(parsedData.data)
            }
            
            return parsedData
        } catch (e) {
            throw new Error('Invalid subscription data format')
        }
    }

    const fetchSubscriptionData = async () => {
        // Si on est sur la page de connexion, ne pas charger les données
        if (window.location.pathname === '/') {
            return;
        }

        // Si on n'a pas de token, ne pas charger les données
        if (!localStorage.getItem('discord_token')) {
            return;
        }

        loading.value = true
        error.value = null
        try {
            // Get the impersonated user ID if any
            const impersonateId = impersonationStore.getImpersonatedId()
            const response = await subscriptionService.getSubscriptionStatus(impersonateId)
            
            if (!response.data || typeof response.data !== 'object') {
                throw new Error(`Invalid API response: ${typeof response.data}. Expected object, got ${JSON.stringify(response.data).substring(0, 100)}...`)
            }
            
            const parsedData = parseSubscriptionData(response.data)
            subscriptionData.value = parsedData
            // Stocker les données dans le localStorage pour la persistance
            localStorage.setItem('subscription_info', JSON.stringify(parsedData))
        } catch (e) {
            error.value = `Error fetching subscription data: ${e.message}`
            // En cas d'erreur, essayer d'utiliser les données en cache
            const cachedData = localStorage.getItem('subscription_info')
            if (cachedData) {
                try {
                    subscriptionData.value = parseSubscriptionData(JSON.parse(cachedData))
                } catch (cacheError) {
                    error.value = `Error parsing cached data: ${cacheError.message}`
                }
            }
        } finally {
            loading.value = false
        }
    }

    // Watch for changes in impersonation state
    watch(() => impersonationStore.currentUser, () => {
        fetchSubscriptionData()
    })

    // Charger les données au montage seulement si on a un token
    onMounted(() => {
        if (localStorage.getItem('discord_token')) {
            fetchSubscriptionData()
        }
    })

    return {
        subscriptionData,
        error,
        loading,
        fetchSubscriptionData
    }
}

<template>
    <div class="dashboard-container">
        <button class="menu-toggle" @click="toggleMenu">
            <span class="menu-icon"></span>
        </button>

        <nav class="dashboard-nav" :class="{ 'menu-open': isMenuOpen }">
            <div class="user-info">
                <img :src="userAvatarUrl" alt="Avatar" class="user-avatar" @error="$event.target.src='https://cdn.discordapp.com/embed/avatars/0.png'">
                <p>{{ userName }}</p>
                <div v-if="isAdmin" class="admin-impersonation">
                    <div class="search-container">
                        <input 
                            type="text" 
                            v-model="searchQuery" 
                            @input="searchUsers" 
                            placeholder="Rechercher un utilisateur..."
                            class="user-search"
                        />
                        <div v-if="searchResults.length && showResults" class="search-results">
                            <div 
                                v-for="user in searchResults" 
                                :key="user.id"
                                @click="impersonateUser(user)"
                                class="search-result-item"
                            >
                                <img 
                                    :src="user.discordAvatarUrl || 'https://cdn.discordapp.com/embed/avatars/0.png'" 
                                    :alt="user.twitchName" 
                                    class="mini-avatar"
                                    @error="$event.target.src='https://cdn.discordapp.com/embed/avatars/0.png'"
                                />
                                <span>{{ user.twitchName }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="impersonatedUser" class="impersonation-active">
                        <p>Vue en tant que: {{ impersonatedUser.twitchName }}</p>
                        <button @click="stopImpersonation" class="stop-impersonation">
                            Revenir à mon compte
                        </button>
                    </div>
                </div>
            </div>
            
            <ul>
                <li @click="setActiveSection('premium')" :class="{ active: activeSection === 'premium' }">Premium</li>
                <li @click="setActiveSection('general')" :class="{ active: activeSection === 'general' }">General</li>
                <li @click="setActiveSection('roleplay')" :class="{ active: activeSection === 'roleplay' }">GTA RP</li>
                <li @click="setActiveSection('safespace')" :class="{ active: activeSection === 'safespace' }">SafeSpace</li>
                <li v-if="showModeration" @click="setActiveSection('moderation')" :class="{ active: activeSection === 'moderation' }">Modération</li>
                <li v-if="showModeration" @click="setActiveSection('ia')" :class="{ active: activeSection === 'ia' }">Intelligence Artificielle</li>
            </ul>
            <button class="logout-button" @click="logout">Se déconnecter</button>
        </nav>

        <div class="dashboard-content">
            <component 
                :is="currentComponent" 
                v-if="currentComponent"
                @premium-updated="handlePremiumUpdate"
            ></component>
        </div>

        <div class="menu-overlay" v-if="isMenuOpen" @click="toggleMenu"></div>
    </div>
</template>

<script>
import Premium from './Premium.vue'
import General from './General.vue'
import RolePlay from './RolePlay.vue'
import SafeSpace from './SafeSpace.vue'
import Moderation from './Moderation.vue'
import IntelligenceArtificielle from './IntelligenceArtificielle.vue'
import { notifications } from '../store/notifications'
import { impersonationStore } from '../store/impersonation'
import { api } from '../services/api'
import { authService } from '../services/auth'
import { useSubscriptionData } from '../composables/useSubscriptionData'
import { ref, computed, onMounted } from 'vue'

export default {
    name: 'Dashboard',
    components: {
        Premium,
        General,
        RolePlay,
        SafeSpace,
        Moderation,
        IntelligenceArtificielle
    },
    setup() {
        const { fetchSubscriptionData } = useSubscriptionData()
        
        const activeSection = ref('premium')
        const sections = {
            premium: Premium,
            general: General,
            roleplay: RolePlay,
            safespace: SafeSpace,
            moderation: Moderation,
            ia: IntelligenceArtificielle
        }
        const isMenuOpen = ref(false)
        const isAdmin = ref(false)
        const searchQuery = ref('')
        const searchResults = ref([])
        const showResults = ref(false)
        const userName = ref('')
        const userAvatarUrl = ref('')

        const currentComponent = computed(() => sections[activeSection.value])
        const impersonatedUser = computed(() => impersonationStore.currentUser)
        const showModeration = computed(() => process.env.VUE_APP_ENV === 'development')

        const setActiveSection = (section) => {
            activeSection.value = section
            isMenuOpen.value = false
        }

        const toggleMenu = () => {
            isMenuOpen.value = !isMenuOpen.value
        }

        const logout = () => {
            authService.logout()
        }

        const checkAdminRights = async () => {
            try {
                const response = await api.get('/subscriptions/isAdmin')
                isAdmin.value = response.data
            } catch (error) {
                console.error('Error checking admin rights:', error)
                isAdmin.value = false
            }
        }

        const searchUsers = async () => {
            if (!searchQuery.value) {
                searchResults.value = []
                showResults.value = false
                return
            }

            try {
                const response = await api.get(`/subscriptions/search?keyword=${searchQuery.value}`)
                searchResults.value = response.data.users
                showResults.value = true
            } catch (error) {
                console.error('Error searching users:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la recherche d\'utilisateurs',
                    timeout: 5000
                })
            }
        }

        const impersonateUser = async (user) => {
            try {
                // Instead of a dedicated endpoint, we'll just call GetSubInformations with the impersonateId
                const response = await api.get(`/subscriptions?impersonateId=${user.id}`)
                if (response.data.success) {
                    await impersonationStore.setCurrentUser(user)
                    showResults.value = false
                    searchQuery.value = ''
                    searchResults.value = []
                    
                    notifications.add({
                        type: 'success',
                        message: `Vue changée pour ${user.twitchName}`,
                        timeout: 5000
                    })

                    // Recharger les données de souscription
                    await fetchSubscriptionData()
                }
            } catch (error) {
                console.error('Error impersonating user:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du changement d\'utilisateur',
                    timeout: 5000
                })
            }
        }

        const stopImpersonation = async () => {
            try {
                // Call GetSubInformations without impersonateId to stop impersonation
                const response = await api.get('/subscriptions')
                if (response.data.success) {
                    await impersonationStore.setCurrentUser(null)
                    notifications.add({
                        type: 'success',
                        message: 'Retour à votre compte',
                        timeout: 5000
                    })
                    
                    // Recharger les données de souscription
                    await fetchSubscriptionData()
                }
            } catch (error) {
                console.error('Error stopping impersonation:', error)
            }
        }

        const loadUserData = async () => {
            try {
                const userData = JSON.parse(localStorage.getItem('discord_user'))
                if (userData) {
                    userName.value = userData.global_name || userData.username
                    if (userData.avatar) {
                        // Utiliser le format WebP pour une meilleure performance et qualité
                        // Taille 128x128 pour un bon compromis qualité/performance
                        userAvatarUrl.value = `https://cdn.discordapp.com/avatars/${userData.id}/${userData.avatar}.webp?size=128`
                    } else {
                        // Utiliser l'avatar par défaut basé sur le discriminator
                        const defaultIndex = userData.discriminator === "0" ? Math.floor(Math.random() * 6) : parseInt(userData.discriminator) % 5
                        userAvatarUrl.value = `https://cdn.discordapp.com/embed/avatars/${defaultIndex}.png`
                    }
                }
                
                await fetchSubscriptionData()
                await checkAdminRights()
            } catch (error) {
                console.error('Error in loadUserData:', error)
                userName.value = 'Unknown User'
                userAvatarUrl.value = 'https://cdn.discordapp.com/embed/avatars/0.png'
            }
        }

        const handlePremiumUpdate = async () => {
            await fetchSubscriptionData()
        }

        onMounted(loadUserData)

        return {
            activeSection,
            sections,
            isMenuOpen,
            isAdmin,
            searchQuery,
            searchResults,
            showResults,
            userName,
            userAvatarUrl,
            currentComponent,
            impersonatedUser,
            showModeration,
            setActiveSection,
            toggleMenu,
            logout,
            searchUsers,
            impersonateUser,
            stopImpersonation,
            handlePremiumUpdate
        }
    }
}
</script>

<style scoped>
.dashboard-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.menu-toggle {
    display: none;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    background: #004B55;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0;
}

.menu-icon {
    position: relative;
    display: block;
    width: 20px;
    height: 2px;
    background: white;
    margin: 0 auto;
}

.menu-icon::before,
.menu-icon::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: white;
    left: 0;
}

.menu-icon::before {
    top: -6px;
}

.menu-icon::after {
    bottom: -6px;
}

.dashboard-nav {
    width: 250px;
    background-color: #004B55;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    transition: transform 0.3s ease;
}

.dashboard-content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 250px;
    height: 100vh;
    overflow-y: auto;
    transition: margin-left 0.3s ease;
}

.menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

/* Styles mobiles */
@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }

    .menu-overlay {
        display: block;
    }

    .dashboard-nav {
        transform: translateX(-100%);
    }

    .dashboard-nav.menu-open {
        transform: translateX(0);
    }

    .dashboard-content {
        margin-left: 0;
        padding-top: 80px;
    }
}

.user-info {
    padding: 20px;
    text-align: center;
    background-color: #003C45;
    border-bottom: 1px solid #006D7F;
}

.user-info p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.user-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #006D7F;
}

.dashboard-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    overflow-y: auto;
}

.dashboard-nav li {
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #006D7F;
}

.dashboard-nav li.active {
    background-color: #006D7F;
}

.logout-button {
    padding: 20px;
    background-color: #004B55;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
}

.logout-button:hover {
    background-color: #006D7F;
}

.admin-impersonation {
    margin-top: 1rem;
    padding: 0.5rem;
}

.search-container {
    position: relative;
    width: 100%;
}

.user-search {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: var(--color-background);
    color: var(--color-text);
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--color-background);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    margin-top: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
}

.search-result-item {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-text);
    transition: background-color 0.2s;
    background-color: var(--color-background);
}

.search-result-item:hover {
    background-color: var(--color-background-hover);
}

.mini-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 0.8rem;
    object-fit: cover;
    background-color: var(--color-background-soft);
}

.impersonation-active {
    margin-top: 0.8rem;
    padding: 0.8rem;
    background-color: var(--color-background-soft);
    border-radius: 4px;
    font-size: 0.9rem;
    border: 1px solid var(--color-border);
}

.impersonation-active p {
    color: var(--color-text);
    margin-bottom: 0.5rem;
}

.stop-impersonation {
    background-color: var(--color-danger);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
    width: 100%;
}

.stop-impersonation:hover {
    background-color: var(--color-danger-hover);
}

:root {
    --color-background: #1a1a1a;
    --color-background-soft: #242424;
    --color-background-hover: #2c2c2c;
    --color-border: #363636;
    --color-text: #ffffff;
    --color-danger: #dc3545;
    --color-danger-hover: #c82333;
}
</style>
<template>
    <div class="callback-container">
        <p>Authentification en cours...</p>
    </div>
</template>

<script>
import { authService } from '../services/auth';
import { notifications } from '../store/notifications';

export default {
    name: 'Callback',
    async mounted() {
        try {
            const hash = window.location.hash.substring(1);
            const params = new URLSearchParams(hash);
            const accessToken = params.get('access_token');

            if (!accessToken) {
                throw new Error('No access token found');
            }

            // Store token securely
            localStorage.setItem('discord_token', accessToken);

            try {
                // Get user data
                const userData = await authService.getUserInfo();
                localStorage.setItem('discord_user', JSON.stringify(userData));

                // Get subscription data
                const subscriptionData = await authService.getSubscriptionInfo();
                // On stocke directement l'objet, pas besoin de le parser à nouveau
                localStorage.setItem('subscription_info', JSON.stringify(subscriptionData));

                // Show success notification
                notifications.add({
                    type: 'success',
                    message: 'Connexion réussie !',
                    timeout: 3000
                });

                // Redirect to dashboard
                this.$router.push('/dashboard');
            } catch (error) {
                // L'erreur a déjà été gérée par l'intercepteur API
                this.$router.push('/');
            }
        } catch (error) {
            console.error('Authentication error:', error);
            notifications.add({
                type: 'error',
                message: 'Erreur lors de l\'authentification. Veuillez réessayer.',
                timeout: 5000
            });
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>
.callback-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>
import { reactive } from 'vue'
import { api } from '../services/api'

export const impersonationStore = reactive({
    currentUser: null,
    subscription: null,

    async setCurrentUser(userData) {
        this.currentUser = userData
        this.subscription = userData.subscription

        if (userData) {
            localStorage.setItem('impersonated_user', JSON.stringify(userData))
            // Recharger immédiatement les données à jour
            try {
                const response = await api.get('/subscriptions')
                this.updateSubscription(response)
            } catch (error) {
                console.error('Error refreshing subscription:', error)
            }
        } else {
            localStorage.removeItem('impersonated_user')
        }
    },

    clearCurrentUser() {
        this.currentUser = null
        this.subscription = null
        localStorage.removeItem('impersonated_user')
    },

    isImpersonating() {
        return !!this.currentUser
    },

    updateSubscription(newSubscription) {
        if (!newSubscription) return

        this.subscription = newSubscription
        if (this.currentUser) {
            const updatedUser = {
                ...this.currentUser,
                subscription: newSubscription
            }
            this.currentUser = updatedUser
            localStorage.setItem('impersonated_user', JSON.stringify(updatedUser))
        }
    },

    loadSavedImpersonation() {
        const savedUser = localStorage.getItem('impersonated_user')
        if (savedUser) {
            try {
                const userData = JSON.parse(savedUser)
                this.currentUser = userData
                this.subscription = userData.subscription
            } catch (error) {
                console.error('Error loading saved impersonation:', error)
                this.clearCurrentUser()
            }
        }
    },

    getImpersonatedId() {
        return this.currentUser?.id || null
    }
})

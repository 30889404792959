import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../components/Welcome.vue'
import Dashboard from '../components/Dashboard.vue'
import Callback from '../components/Callback.vue'
import Moderation from '../components/Moderation.vue'
import IntelligenceArtificielle from '../components/IntelligenceArtificielle.vue'

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: { skipHealthCheck: true } // Skip health check on welcome page since it does its own
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: '/gift',
    name: 'GiftPremium',
    component: () => import('../components/GiftPremium.vue')
  },
  {
    path: '/premium',
    name: 'Premium',
    component: () => import('../components/Premium.vue'),
    meta: { requiresAuth: true }
  },
  ...(process.env.VUE_APP_ENV === 'development' ? [{
    path: '/moderation',
    name: 'Moderation',
    component: Moderation,
    meta: { requiresAuth: true }
  },
  {
    path: '/ia',
    name: 'IntelligenceArtificielle',
    component: IntelligenceArtificielle,
    meta: { requiresAuth: true }
  }] : [])
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation guard
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('discord_token')
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !token) {
    next('/')
  } else if (to.path === '/' && token) {
    next('/dashboard')
  } else {
    next()
  }
})

export default router
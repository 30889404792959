<template>
    <div class="welcome-container">
        <div v-if="apiError" class="api-error-banner">
            <div class="error-content">
                <i class="error-icon">⚠️</i>
                <div class="error-message">
                    <h3>Le dashboard est actuellement indisponible</h3>
                    <p>Vous pouvez utiliser les commandes Discord en attendant le rétablissement du service :</p>
                    <div class="commands-list">
                        <code>@buddibot config</code> - Configurer le bot
                        <code>@buddibot premium</code> - Gérer votre abonnement premium
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!apiError" class="content">
            <template v-if="isLoggedIn">
                <img 
                    v-if="userAvatar" 
                    :src="userAvatar" 
                    :alt="username" 
                    class="avatar"
                />
                <h1>Bonjour {{ username }} !</h1>
                <div class="buttons">
                    <router-link to="/dashboard" class="dashboard-button">
                        Accéder au Dashboard
                    </router-link>
                    <button @click="logout" class="logout-button">
                        Ce n'est pas moi
                    </button>
                </div>
            </template>
            <template v-else>
                <h1>Bienvenue chez Buddibot</h1>
                <div class="buttons">
                    <button @click="redirectToDiscord" class="login-button">
                        <img src="../assets/discord-mark-white.svg" alt="Discord logo" class="discord-logo">
                        Se connecter avec Discord
                    </button>
                    <button @click="redirectToDiscordServer" class="create-account-button">
                        <img src="../assets/discord-mark-white.svg" alt="Discord logo" class="discord-logo">
                        Créer un compte
                    </button>
                </div>
                
                <div class="gift-container">
                    <div class="gift-section">
                        <p class="gift-text">Vous souhaitez offrir Premium à un streamer ?</p>
                        <router-link to="/gift" class="gift-button">
                            <span class="gift-icon">🎁</span>
                            Offrir Premium
                        </router-link>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { api } from '../services/api'

export default {
    name: 'Welcome',
    data() {
        return {
            apiError: false,
            username: '',
            userAvatar: null,
            isLoggedIn: false
        }
    },
    created() {
        // Check API health immediately
        this.checkApiHealth();
    },
    methods: {
        async checkApiHealth() {
            try {
                // Test de l'API avec un endpoint simple
                await api.get('/subscriptions/health')
                this.apiError = false
                this.loadUserData();
            } catch (error) {
                console.error('API inaccessible:', error)
                this.apiError = true
            }
        },
        async loadUserData() {
            // Vérifier si un token existe
            const token = localStorage.getItem('discord_token');
            if (!token) {
                this.isLoggedIn = false;
                return;
            }

            // Récupérer les informations utilisateur depuis le localStorage
            const userData = JSON.parse(localStorage.getItem('discord_user'));
            if (userData && userData.username) {
                this.username = userData.global_name || userData.username; // Fallback sur username si global_name n'existe pas
                this.userAvatar = `https://cdn.discordapp.com/avatars/${userData.id}/${userData.avatar}.png`;
                this.isLoggedIn = true;
            } else {
                // Si pas de données utilisateur valides, on déconnecte
                this.logout();
            }
        },
        redirectToDiscord() {
            const clientId = '911047298262376460';
            const redirectUri = encodeURIComponent(process.env.VUE_APP_CALLBACK_URL);
            const discordOAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=identify`;
            window.location.href = discordOAuthUrl;
        },
        redirectToDiscordServer() {
            window.location.href = 'https://discord.gg/ryAgVYgU'
        },
        logout() {
            // Supprimer les informations de l'utilisateur du localStorage
            localStorage.removeItem('discord_token');
            localStorage.removeItem('discord_user');
            localStorage.removeItem('subscription_info');
            this.isLoggedIn = false;
            this.username = '';
            this.userAvatar = null;
        }
    }
}
</script>

<style scoped>
.welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
}

.content {
    text-align: center;
    background: rgba(255, 255, 255, 0.05);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 4px solid rgba(255, 255, 255, 0.1);
}

h1 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: white;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;
}

.dashboard-button, .login-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    font-size: 1.1rem;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}

.dashboard-button:hover, .login-button:hover {
    background-color: #45a049;
}

.login-button, .create-account-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 250px;
}

.login-button {
    background-color: #5865F2;
    color: white;
}

.create-account-button {
    background-color: #2D3748;
    color: white;
}

.login-button:hover {
    background-color: #4752C4;
}

.create-account-button:hover {
    background-color: #1A202C;
}

.logout-button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    transition: all 0.3s;
}

.logout-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.3);
}

.gift-container {
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: rgba(45, 55, 72, 0.3);
    border-radius: 12px;
    text-align: center;
}

.gift-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.gift-text {
    color: #A0AEC0;
    font-size: 1rem;
    margin: 0;
}

.gift-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #553C9A;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.gift-button:hover {
    background-color: #44337A;
}

.gift-icon {
    font-size: 1.2rem;
}

.api-error-banner {
    background: rgba(220, 38, 38, 0.1);
    border: 1px solid rgba(220, 38, 38, 0.2);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.error-content {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.error-icon {
    font-size: 1.5rem;
    line-height: 1;
}

.error-message {
    flex: 1;
}

.error-message h3 {
    color: #DC2626;
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
}

.error-message p {
    color: rgba(255, 255, 255, 0.8);
    margin: 0 0 1rem 0;
}

.commands-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 6px;
}

.commands-list code {
    font-family: monospace;
    color: #10B981;
    background: rgba(16, 185, 129, 0.1);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    display: inline-block;
}

.discord-logo {
    width: 20px;
    height: 20px;
}
</style>
<template>
  <div class="notifications-container">
    <TransitionGroup name="notification">
      <div v-for="notification in notifications.items" 
           :key="notification.id" 
           :class="['notification', notification.type]">
        <div class="notification-content">
          {{ notification.message }}
          <button class="close" @click="notifications.remove(notification.id)">&times;</button>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { notifications } from '../store/notifications'

export default {
  name: 'NotificationList',
  setup() {
    return {
      notifications
    }
  }
}
</script>

<style scoped>
.notifications-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notification {
  padding: 12px 20px;
  border-radius: 4px;
  min-width: 280px;
  max-width: 400px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  color: white;
}

.notification-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 0 5px;
}

.close:hover {
  opacity: 0.8;
}

.success {
  background-color: #4caf50;
}

.error {
  background-color: #f44336;
}

.info {
  background-color: #2196f3;
}

.warning {
  background-color: #ff9800;
}

/* Transition animations */
.notification-enter-active,
.notification-leave-active {
  transition: all 0.3s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>

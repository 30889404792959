<template>
    <div class="premium-container">
        <div class="header-section">
            <h2>Section Premium</h2>
            <div class="premium-icon">⭐</div>
        </div>
        
        <!-- Loading state -->
        <div v-if="isLoading" class="loading-spinner">
            <div class="spinner"></div>
            Chargement...
        </div>

        <!-- Premium status -->
        <div v-else>
            <div class="status-section" :class="statusClass">
                <div class="status-content">
                    <div class="status-icon">
                        <span v-if="subscriptionStatus.IsVIP">👑</span>
                        <span v-else-if="subscriptionStatus.IsActive">⭐</span>
                        <span v-else>💫</span>
                    </div>
                    <div class="status-info">
                        <p class="status-label">Statut</p>
                        <p class="status-value">{{ statusText }}</p>
                    </div>
                </div>
            </div>

            <div class="extend-subscription" v-if="!subscriptionStatus.IsVIP">
                <button 
                    v-if="!showPaymentOptions" 
                    @click="showPaymentOptions = true" 
                    :class="['subscribe-button', { 'extend-button': subscriptionStatus.IsActive }]"
                >
                    {{ buttonText }}
                </button>
                <button v-if="showPaymentOptions" @click="showExtendOptions" class="extend-button">
                    Prolonger mon abonnement
                </button>
            </div>

            <!-- Payment form -->
            <div class="payment-form" v-if="showPaymentOptions">
                <div v-if="subscriptionStatus.IsActive" class="extend-info">
                    <p>Votre abonnement sera prolongé à partir de la date d'expiration actuelle ({{ new Date(subscriptionStatus.PremiumExpirationDate).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' }) }}).</p>
                </div>

                <div class="form-group">
                    <label for="twitchName">Nom de la chaîne Twitch</label>
                    <div class="input-group">
                        <input 
                            type="text" 
                            id="twitchName" 
                            v-model="twitchName"
                            :class="{ 'error': twitchNameError }"
                            @input="validateTwitchName"
                            placeholder="Entrez votre nom de chaîne Twitch"
                        />
                        <span class="error-message" v-if="twitchNameError">{{ twitchNameError }}</span>
                    </div>
                </div>

                <div class="form-group">
                    <label for="months">Durée de l'abonnement</label>
                    <select 
                        v-model="months" 
                        id="months"
                        :disabled="!twitchName || !!twitchNameError"
                    >
                        <option
                            v-for="option in subscriptionOptions"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.text }}
                        </option>
                    </select>
                </div>

                <!-- Prix -->
                <div class="price-section" v-if="twitchName && !twitchNameError">
                    <p class="price">
                        Prix : {{ calculatePrice(months) }}€
                        <span v-if="getBonus(months)" class="bonus">
                            {{ getBonus(months) }}
                        </span>
                    </p>
                </div>

                <!-- PayPal Button -->
                <div class="paypal-section" v-if="twitchName && !twitchNameError">
                    <PaypalButton 
                        :twitch-name="twitchName" 
                        :months="months"
                        @payment-success="handlePaymentSuccess"
                        @payment-error="handlePaymentError"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue'
import { useSubscriptionData } from '../composables/useSubscriptionData'
import { notifications } from '../store/notifications'
import PaypalButton from './PaypalButton.vue'
import { subscriptionService } from '../services/subscription'

export default {
    name: 'Premium',
    components: {
        PaypalButton
    },
    setup() {
        const { subscriptionData, error } = useSubscriptionData()
        
        // États réactifs
        const isLoading = ref(false)
        const showPaymentOptions = ref(false)
        const twitchName = ref('')
        const originalTwitchName = ref('')
        const months = ref(1)
        const twitchNameError = ref('')
        const subscriptionStatus = ref({
            IsActive: false,
            IsVIP: false,
            PremiumExpirationDate: null
        })

        const subscriptionOptions = [
            { value: 1, text: '1 mois (6€)' },
            { value: 3, text: '3 mois (18€)' },
            { value: 6, text: '6 mois (36€) + 15 jours bonus offerts' },
            { value: 12, text: '12 mois (72€) + 2 mois bonus offerts' }
        ]

        // Computed properties
        const buttonText = computed(() => {
            return subscriptionStatus.value.IsActive ? 'Prolonger mon abonnement' : 'Souscrire'
        })

        const statusText = computed(() => {
            if (subscriptionStatus.value.IsVIP) {
                return 'VIP (Illimité)'
            }
            
            if (!subscriptionStatus.value.IsActive) {
                return 'Non Premium'
            }
            
            if (subscriptionStatus.value.PremiumExpirationDate) {
                const expirationDate = new Date(subscriptionStatus.value.PremiumExpirationDate)
                return `Premium (Expire le ${expirationDate.toLocaleDateString('fr-FR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                })})`
            }
            
            return 'Premium'
        })

        const statusClass = computed(() => {
            if (subscriptionStatus.value.IsVIP) {
                return 'status-vip'
            }
            return subscriptionStatus.value.IsActive ? 'status-premium' : 'status-normal'
        })

        const updateLocalData = (data) => {
            //console.log('Updating Premium data with:', data)
            
            if (!data?.data) {
                //console.warn('No subscription data found')
                return
            }

            // Mise à jour du statut premium
            subscriptionStatus.value = {
                IsActive: data.data.PremiumStatus === 1,
                IsVIP: data.data.IsVIP === true,
                PremiumExpirationDate: data.data.PremiumExpirationDate
            }

            // Mise à jour du nom Twitch
            if (data.data.TwitchName) {
                twitchName.value = data.data.TwitchName
                originalTwitchName.value = data.data.TwitchName
            }
        }

        const calculatePrice = (months) => {
            return months * 6
        }

        const getBonus = (months) => {
            switch (months) {
                case 6:
                    return '+15 jours bonus offerts'
                case 12:
                    return '+2 mois bonus offerts'
                default:
                    return null
            }
        }

        const validateTwitchName = () => {
            if (!twitchName.value) {
                twitchNameError.value = 'Le nom de la chaîne est requis'
                return false
            }
            
            if (!/^[a-zA-Z0-9_]{4,25}$/.test(twitchName.value)) {
                twitchNameError.value = 'Le nom de la chaîne est invalide'
                return false
            }
            
            twitchNameError.value = ''
            return true
        }

        const handlePaymentSuccess = async (paymentDetails) => {
            isLoading.value = true
            try {
                // Utiliser le service de souscription
                const response = await subscriptionService.createSubscription({
                    twitchName: twitchName.value,
                    months: months.value,
                    paymentDetails
                });

                if (response.data?.success) {
                    notifications.add({
                        type: 'success',
                        message: 'Paiement traité avec succès ! Votre abonnement premium est maintenant actif.',
                        timeout: 5000
                    })
                    
                    // Recharger les données
                    if (subscriptionData.value) {
                        subscriptionData.value.PremiumStatus = 1
                        subscriptionData.value.PremiumExpirationDate = response.data.data.expirationDate
                    }
                    
                    showPaymentOptions.value = false
                } else {
                    throw new Error('Erreur lors de l\'activation de l\'abonnement')
                }
            } catch (error) {
                console.error('Payment processing error:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du traitement du paiement: ' + error.message,
                    timeout: 5000
                })
            } finally {
                isLoading.value = false
            }
        }

        const handlePaymentError = (error) => {
            console.error('Payment error:', error)
            notifications.add({
                type: 'error',
                message: 'Erreur lors du paiement: ' + error.message,
                timeout: 5000
            })
        }

        const showExtendOptions = () => {
            showPaymentOptions.value = true
        }

        // Charger les données au montage du composant
        onMounted(async () => {
            isLoading.value = true
            try {
                // Essayer de charger depuis le cache d'abord
                const cachedData = localStorage.getItem('subscription_info')
                if (cachedData) {
                    try {
                        const parsedData = JSON.parse(cachedData)
                        updateLocalData(parsedData)
                    } catch (e) {
                        console.error('Error parsing cached data:', e)
                    }
                }
            } finally {
                isLoading.value = false
            }
        })

        // Surveiller les changements dans les données d'abonnement
        watch(subscriptionData, (newData) => {
            if (newData) {
                updateLocalData(newData)
            }
        }, { immediate: true })

        // Surveiller les erreurs
        watch(error, (newError) => {
            if (newError) {
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du chargement des données: ' + newError,
                    timeout: 5000
                })
            }
        })

        return {
            isLoading,
            showPaymentOptions,
            twitchName,
            originalTwitchName,
            months,
            twitchNameError,
            subscriptionStatus,
            subscriptionOptions,
            buttonText,
            statusText,
            statusClass,
            calculatePrice,
            getBonus,
            validateTwitchName,
            handlePaymentSuccess,
            handlePaymentError,
            showExtendOptions
        }
    }
}
</script>

<style scoped>
.premium-container {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.header-section {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.header-section h2 {
    margin: 0;
    font-size: 2rem;
    color: #fff;
}

.premium-icon {
    font-size: 2rem;
    margin-left: 1rem;
}

.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: #fff;
}

.spinner {
    border: 4px solid #2D3748;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-right: 1rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.status-section {
    background: #1A202C;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    transition: all 0.3s ease;
    border: 1px solid #2D3748;
}

.status-content {
    display: flex;
    align-items: center;
}

.status-icon {
    font-size: 2.5rem;
    margin-right: 1.5rem;
}

.status-info {
    flex: 1;
}

.status-label {
    font-size: 0.9rem;
    color: #A0AEC0;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.status-value {
    font-size: 1.5rem;
    color: #fff;
    margin: 0.5rem 0 0 0;
    font-weight: 600;
}

.status-vip {
    background: linear-gradient(135deg, #2D3748, #1A202C);
    border: 2px solid #FFD700;
    color: #FFD700;
}

.status-premium {
    background: linear-gradient(135deg, #2D3748, #1A202C);
    border: 2px solid #3498db;
    color: #3498db;
}

.status-premium .status-label,
.status-premium .status-value,
.status-vip .status-label,
.status-vip .status-value {
    color: inherit;
}

.extend-subscription {
    text-align: center;
    margin: 2rem 0;
}

.subscribe-button, .extend-button {
    padding: 1rem 2rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: #004B55;
    color: white;
}

.extend-button {
    background: #004B55;
}

.subscribe-button:hover, .extend-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.3);
    filter: brightness(1.2);
}

.payment-form {
    background: #1A202C;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0,0,0,0.3);
    border: 1px solid #2D3748;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #fff;
    font-weight: 500;
}

.input-group {
    position: relative;
}

input, select {
    width: 100%;
    padding: 0.8rem;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    font-size: 1rem;
    color: #fff;
    transition: all 0.3s ease;
}

input:focus, select:focus {
    outline: none;
    border-color: #3498db;
    background: #364154;
}

input.error {
    border-color: #e74c3c;
    background: rgba(231, 76, 60, 0.1);
}

.error-message {
    color: #e74c3c;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    display: block;
}

select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    padding-right: 2.5rem;
}

select option {
    background: #2D3748;
    color: #fff;
}

.price-section {
    margin: 2rem 0;
    text-align: center;
    background: #2D3748;
    padding: 1.5rem;
    border-radius: 8px;
}

.price {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    margin: 0;
}

.bonus {
    display: inline-block;
    margin-left: 1rem;
    padding: 0.3rem 0.8rem;
    background: #004B55;
    color: #fff;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: normal;
}

.paypal-section {
    margin-top: 2rem;
}

.extend-info {
    background: rgba(0, 75, 85, 0.1);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    color: #A0AEC0;
    border: 1px solid #004B55;
}

input:disabled, select:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: #1A202C;
}
</style>
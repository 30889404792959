<template>
    <div class="container">
        <div class="title-section">
            <div class="title-content">
                <h2>Configuration SafeSpace</h2>
                <p class="description">Configurez les paramètres du module SafeSpace pour protéger votre communauté</p>
            </div>
            <div class="title-controls">
                <div class="toggle-container">
                    <label class="toggle">
                        <input type="checkbox" v-model="isModuleEnabled" @change="handleModuleToggle">
                        <span class="slider"></span>
                    </label>
                    <span class="toggle-label" :class="{ 'active': isModuleEnabled }">{{ isModuleEnabled ? 'Activé' : 'Désactivé' }}</span>
                </div>
                <div class="title-icon">
                    🛡️
                </div>
            </div>
        </div>
        
        <div v-if="isModuleEnabled">
            <div class="form-section">
                <h3>Communauté</h3>
                <p class="section-description">Sélectionnez votre communauté pour une meilleure intégration</p>
                <div class="input-container">
                    <select v-model="communityCode" class="community-select">
                        <option v-for="community in communities" :key="community.Code" :value="community.Code">
                            {{ community.Name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-section">
                <h3>Salon Discord</h3>
                <div class="channel-selector">
                    <div class="radio-group">
                        <label class="radio-label">
                            <input type="radio" v-model="channelType" value="general">
                            <span>Utiliser le salon général</span>
                        </label>
                        <label class="radio-label">
                            <input type="radio" v-model="channelType" value="custom">
                            <span>Spécifier un autre salon</span>
                        </label>
                    </div>
                    <div v-if="channelType === 'custom'" class="custom-channel">
                        <input type="text" v-model="discordChannelId" placeholder="ID du salon Discord">
                        <p class="helper-text">L'ID du salon peut être obtenu en faisant un clic droit sur le salon et en sélectionnant "Copier l'identifiant"</p>
                    </div>
                </div>
            </div>

            <div v-if="isPremium" class="form-section">
                <h3>Options avancées</h3>
                <div class="options-container">
                    <div class="toggle-container">
                        <label class="toggle">
                            <input type="checkbox" v-model="autoban">
                            <span class="slider"></span>
                        </label>
                        <span class="toggle-label" :class="{ 'active': autoban }">Bannissement automatique</span>
                    </div>
                    <p class="helper-text">Active le bannissement automatique des utilisateurs signalé dans votre groupe</p>
                </div>
            </div>

            <div class="actions-section">
                <button @click="saveChanges" class="save-button" :disabled="isSaving">
                    {{ isSaving ? 'Enregistrement...' : 'Enregistrer les modifications' }}
                </button>
            </div>
        </div>
        <div v-else class="module-disabled">
            <p>Activez le module pour accéder aux paramètres de configuration</p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useSubscriptionData } from '../composables/useSubscriptionData'
import { notifications } from '../store/notifications'
import { api } from '../services/api'
import { logger } from '../utils/logger'

export default {
    name: 'SafeSpace',
    setup() {
        const { subscriptionData, error, loading } = useSubscriptionData()
        
        const communityCode = ref('')
        const discordChannelId = ref('')
        const communities = ref([])
        const isModuleEnabled = ref(false)
        const isPremium = ref(false)
        const isSaving = ref(false)
        const autoban = ref(false)
        const channelType = ref('general')

        const updateLocalData = (data) => {
            logger.log('Updating SafeSpace data with:', data)
            
            try {
                // Parse data if it's a string
                const parsedData = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
                
                // Chercher le module SafeSpace (ModuleType: 102) dans BotModules
                const safeSpaceData = parsedData?.BotModules?.find(m => m.ModuleType === 102)
                
                if (!safeSpaceData) {
                    logger.warn('No safespace module data found')
                    return
                }

                isModuleEnabled.value = safeSpaceData.IsActive || false
                isPremium.value = parsedData?.PremiumStatus === 1 || parsedData?.IsVIP === true
                autoban.value = safeSpaceData.autoban || false
                communityCode.value = safeSpaceData.CommunityCode || ''

                // Update communities list
                if (safeSpaceData.SafeSpaceCommunities && Array.isArray(safeSpaceData.SafeSpaceCommunities)) {
                    communities.value = safeSpaceData.SafeSpaceCommunities
                }

                // Configuration des canaux
                if (safeSpaceData.SSDiscordChannelId) {
                    channelType.value = 'custom'
                    discordChannelId.value = safeSpaceData.SSDiscordChannelId
                } else {
                    channelType.value = 'general'
                    discordChannelId.value = ''
                }
            } catch (error) {
                logger.error('Error parsing subscription data:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du chargement des données',
                    timeout: 5000
                })
            }
        }

        const handleModuleToggle = async () => {
            isSaving.value = true
            try {
                const response = await api.post('/subscriptions/modules', {
                    isActive: isModuleEnabled.value,
                    communityCode: communityCode.value,
                    discordChannelId: channelType.value === 'custom' ? discordChannelId.value : null,
                    autoban: autoban.value,
                    moduleType: 102
                })

                if (response.data.success) {
                    notifications.add({
                        type: 'success',
                        message: `Module ${isModuleEnabled.value ? 'activé' : 'désactivé'}`,
                        timeout: 5000
                    })
                }
            } catch (error) {
                logger.error('Error toggling module:', error)
                isModuleEnabled.value = !isModuleEnabled.value // Revert the change
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la mise à jour du module',
                    timeout: 5000
                })
            } finally {
                isSaving.value = false
            }
        }

        const saveChanges = async () => {
            isSaving.value = true
            try {
                const response = await api.post('/subscriptions/modules', {
                    isActive: isModuleEnabled.value,
                    communityCode: communityCode.value,
                    discordChannelId: channelType.value === 'custom' ? discordChannelId.value : null,
                    autoban: autoban.value,
                    moduleType: 102
                })

                if (response.data.success) {
                    notifications.add({
                        type: 'success',
                        message: 'Configuration sauvegardée',
                        timeout: 5000
                    })
                }
            } catch (error) {
                logger.error('Error saving changes:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la sauvegarde',
                    timeout: 5000
                })
            } finally {
                isSaving.value = false
            }
        }

        // Charger les données au montage du composant
        onMounted(async () => {
            // Essayer de charger depuis le cache d'abord
            const cachedData = localStorage.getItem('subscription_info')
            if (cachedData) {
                try {
                    const parsedData = JSON.parse(cachedData)
                    updateLocalData(parsedData)
                } catch (e) {
                    logger.error('Error parsing cached data:', e)
                }
            }
        })

        // Surveiller les changements dans les données d'abonnement
        watch(subscriptionData, (newData) => {
            if (newData) {
                updateLocalData(newData)
            }
        }, { immediate: true })

        // Surveiller les erreurs
        watch(error, (newError) => {
            if (newError) {
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du chargement des données: ' + newError,
                    timeout: 5000
                })
            }
        })

        return {
            communityCode,
            discordChannelId,
            communities,
            isModuleEnabled,
            isPremium,
            isSaving,
            autoban,
            channelType,
            loading,
            handleModuleToggle,
            saveChanges
        }
    }
}
</script>

<style scoped>
.container {
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
}

.title-section {
    background: #1A202C;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #2D3748;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title-content {
    flex: 1;
}

.title-content h2 {
    color: white;
    margin: 0 0 0.5rem 0;
    font-size: 1.8rem;
    font-weight: 600;
}

.title-content .description {
    color: #A0AEC0;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
}

.title-controls {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 120px;
}

.toggle-label {
    color: #666;
    font-size: 0.9rem;
    font-weight: 500;
    width: 60px;
}

.toggle-label.active {
    color: #00b894;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
}

.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2a2a2a;
    transition: .3s;
    border-radius: 34px;
    border: 1px solid #3a3a3a;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: #666;
    transition: .3s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #004B55;
}

input:checked + .slider:before {
    transform: translateX(18px);
    background-color: white;
}

input:focus + .slider {
    box-shadow: 0 0 1px #004B55;
}

.title-icon {
    font-size: 2.5rem;
    margin-left: 2rem;
    opacity: 0.8;
}

.form-section {
    background: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h3 {
    color: white;
    margin: 0 0 1rem 0;
    font-size: 1.3rem;
}

.section-description {
    color: #A0AEC0;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
}

.input-container {
    width: 100%;
    margin-bottom: 1rem;
}

.community-select, .channel-input {
    width: 100%;
    padding: 0.8rem 1rem;
    background: #2a2a2a;
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    color: #ffffff;
    font-size: 1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.community-select {
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    padding-right: 2.5rem;
}

.community-select option {
    background: #2a2a2a;
    color: #ffffff;
}

.channel-input::placeholder {
    color: #666;
}

.community-select:hover, .channel-input:hover {
    border-color: #4a4a4a;
}

.community-select:focus, .channel-input:focus {
    outline: none;
    border-color: #004B55;
    background-color: #2f2f2f;
}

.helper-text {
    color: #A0AEC0;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.save-button {
    background-color: #004B55;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.save-button:hover {
    background-color: #00596B;
}

.save-button:disabled {
    background-color: #2D3748;
    cursor: not-allowed;
    opacity: 0.7;
}

.channel-selector {
    margin-top: 1rem;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1rem;
}

.radio-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    cursor: pointer;
}

.radio-label input[type="radio"] {
    margin: 0;
    cursor: pointer;
}

.custom-channel {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
}

.custom-channel input {
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    padding: 0.75rem;
    color: white;
    width: 100%;
    max-width: 300px;
}

.custom-channel input:focus {
    outline: none;
    border-color: #004B55;
    box-shadow: 0 0 0 1px #004B55;
}

.checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.checkbox-label input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin: 0;
    cursor: pointer;
}

.options-container {
    margin-top: 1rem;
}

.options-container .toggle-container {
    margin-bottom: 0.5rem;
}

.options-container .helper-text {
    margin-left: 3.5rem;
}

.module-disabled {
    text-align: center;
    padding: 2rem;
    background: #1A202C;
    border-radius: 12px;
    margin-top: 1rem;
    border: 1px solid #2D3748;
    color: #A0AEC0;
}
</style>

<template>
  <div class="general-container">
    <div class="header">
      <h2>Vue d'ensemble du compte</h2>
      <p class="description">Consultez les informations de votre compte Buddibot</p>
    </div>
    
    <div v-if="loading" class="loading-container">
      <p>Chargement des informations...</p>
    </div>
    <div v-else-if="error" class="error-container">
      <p>{{ error }}</p>
    </div>
    <div v-else-if="userInfo" class="account-info">
      <div class="info-section">
        <div class="section-header">
          <h3>Informations générales</h3>
        </div>
        <div class="info-content">
          <div class="info-item">
            <span class="label">Nom Twitch</span>
            <span class="value">{{ userInfo.TwitchName }}</span>
          </div>
          <div class="info-item">
            <span class="label">ID Twitch</span>
            <span class="value">{{ userInfo.TwitchUserId }}</span>
          </div>
          <div class="info-item">
            <span class="label">Channel Discord</span>
            <span class="value">{{ userInfo.DiscordChannelId || 'Non configuré' }}</span>
          </div>
          <div class="info-item">
            <span class="label">Statut</span>
            <div class="value-with-toggle">
              <span class="value" :class="{ 'status-active': userInfo.IsActive }">
                {{ userInfo.IsActive ? 'Actif' : 'Inactif' }}
              </span>
              <div class="toggle-container">
                <label class="toggle">
                  <input type="checkbox" v-model="userInfo.IsActive" @change="updateAccountStatus">
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="info-item">
            <span class="label">VIP</span>
            <span class="value badge" :class="{ 'badge-active': userInfo.IsVIP }">
              {{ userInfo.IsVIP ? 'Oui' : 'Non' }}
            </span>
          </div>
          <div class="info-item">
            <span class="label">Fondateur</span>
            <span class="value badge" :class="{ 'badge-active': userInfo.IsFounder }">
              {{ userInfo.IsFounder ? 'Oui' : 'Non' }}
            </span>
          </div>
        </div>
      </div>

      <div class="info-section">
        <div class="section-header">
          <h3>Premium <span v-if="isPremium" class="premium-badge">PREMIUM</span></h3>
        </div>
        <div class="info-content">
          <div class="info-item">
            <span class="label">Date d'expiration</span>
            <span class="value" :class="{ 'premium-active': isPremium }">
              {{ formatDate(userInfo.PremiumExpirationDate) }}
            </span>
          </div>
          <div class="info-item">
            <span class="label">Buddi est modérateur</span>
            <span class="value badge" :class="{ 'badge-active': userInfo.HasBuddiAsModerator }">
              {{ userInfo.HasBuddiAsModerator ? 'Oui' : 'Non' }}
            </span>
          </div>
          <div v-if="!isPremium" class="premium-promo">
            <p class="premium-promo-text">
              Débloquez toutes les fonctionnalités premium et profitez d'une expérience enrichie !
            </p>
            <router-link to="/premium" class="premium-cta-btn">
              Passer en Premium
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSubscriptionData } from '../composables/useSubscriptionData'
import { notifications } from '../store/notifications'
import { ref, computed, watch, onMounted } from 'vue'
import { api } from '../services/api'

export default {
  name: 'General',
  setup() {
    const { subscriptionData, error, loading, fetchSubscriptionData } = useSubscriptionData()
    const userInfo = ref(null)

    const isPremium = computed(() => {
      if (!userInfo.value) return false
      const now = new Date()
      const expirationDate = new Date(userInfo.value.PremiumExpirationDate)
      return expirationDate > now
    })

    const formatDate = (dateString) => {
      if (!dateString) return 'Non disponible'
      const date = new Date(dateString)
      return new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date)
    }

    const updateAccountStatus = async () => {
      try {
        const response = await api.post('/subscriptions/updateStatus', {
          isActive: userInfo.value.IsActive
        })
        
        if (response.data.success) {
          notifications.add({
            type: 'success',
            message: `Compte ${userInfo.value.IsActive ? 'activé' : 'désactivé'}`,
            timeout: 5000
          })
          await fetchSubscriptionData()
        }
      } catch (error) {
        console.error('Error updating account status:', error)
        userInfo.value.IsActive = !userInfo.value.IsActive
        notifications.add({
          type: 'error',
          message: 'Erreur lors de la mise à jour du statut',
          timeout: 5000
        })
      }
    }

    // Surveiller les changements dans les données de souscription
    watch(subscriptionData, (newData) => {
      if (newData?.data) {
        try {
          const parsedData = typeof newData.data === 'string' ? JSON.parse(newData.data) : newData.data
          //console.log('Parsed subscription data:', parsedData)
          userInfo.value = parsedData
        } catch (e) {
          //console.error('Error parsing subscription data:', e)
          error.value = 'Erreur lors du traitement des données'
        }
      }
    }, { immediate: true })

    onMounted(() => {
      fetchSubscriptionData()
    })

    return {
      userInfo,
      isPremium,
      loading,
      error,
      formatDate,
      updateAccountStatus
    }
  }
}
</script>

<style scoped>
.general-container {
  padding: 2rem;
  max-width: 900px;
  margin: 0 auto;
}

.header {
  margin-bottom: 2rem;
}

.header h2 {
  color: white;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
}

.description {
  color: #A0AEC0;
  font-size: 1.1rem;
}

.account-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.info-section {
  background-color: #1A202C;
  border: 1px solid #2D3748;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.section-header h3 {
  color: white;
  font-size: 1.3rem;
  margin: 0;
}

.info-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #2D3748;
}

.info-item:last-child {
  border-bottom: none;
}

.label {
  color: #A0AEC0;
  font-size: 0.95rem;
}

.value {
  color: white;
  font-weight: 500;
}

.value-with-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  flex-shrink: 0;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2D3748;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #004B55;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.85rem;
  background-color: #2D3748;
}

.badge-active {
  background-color: #004B55;
  color: white;
}

.status-active {
  color: #004B55;
}

.premium-badge {
  background: linear-gradient(135deg, #FFD700 0%, #FFA500 100%);
  color: #1A202C;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
}

.premium-active {
  color: #FFD700;
}

.premium-promo {
  margin-top: 1.5rem;
  text-align: center;
  padding: 1.5rem;
  background: rgba(0, 75, 85, 0.1);
  border-radius: 8px;
  border: 2px dashed #004B55;
}

.premium-promo-text {
  color: #A0AEC0;
  margin-bottom: 1rem;
}

.premium-cta-btn {
  display: inline-block;
  background: linear-gradient(135deg, #004B55 0%, #006D7F 100%);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: transform 0.2s, box-shadow 0.2s;
}

.premium-cta-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 75, 85, 0.2);
}

.loading-container {
  text-align: center;
  padding: 2rem;
  color: #A0AEC0;
}

.error-container {
  text-align: center;
  padding: 2rem;
  color: #A0AEC0;
}

@media (max-width: 768px) {
  .general-container {
    padding: 1rem;
  }
  
  .account-info {
    grid-template-columns: 1fr;
  }
}
</style>
<template>
    <div>
        <h2>Paiement via PayPal</h2>
        <div id="paypal-button-container"></div>
        <p id="result-message"></p>
    </div>
</template>

<script>
import { notifications } from '../store/notifications'
import { subscriptionService } from '../services/subscription'

export default {
    name: 'PaypalButton',
    props: {
        twitchName: {
            type: String,
            required: true
        },
        months: {
            type: Number,
            required: true
        },
        donorName: {
            type: String,
            default: ''
        }
    },
    mounted() {
        const script = document.createElement('script');
        const paypalUrl = process.env.VUE_APP_PAYPAL_URL || 'https://www.paypal.com';
        script.src = `${paypalUrl}/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&currency=EUR`;
        script.async = true;
        script.onload = this.initializePayPalButton;
        script.onerror = () => {
            notifications.add({
                type: 'error',
                message: 'Erreur lors du chargement de PayPal. Veuillez réessayer plus tard.',
                timeout: 8000
            });
        };
        document.head.appendChild(script);
    },
    methods: {
        initializePayPalButton() {
            if (!window.paypal) {
                notifications.add({
                    type: 'error',
                    message: 'PayPal SDK non chargé correctement. Veuillez rafraîchir la page.',
                    timeout: 8000
                });
                return;
            }

            window.paypal.Buttons({
                style: {
                    shape: "rect",
                    layout: "vertical",
                    color: "blue",
                    label: "pay",
                },
                createOrder: async (data, actions) => {
                    try {
                        const pricePerMonth = 6;
                        const amount = this.months * pricePerMonth;
                        
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: amount.toString(),
                                    currency_code: 'EUR'
                                },
                                description: `Abonnement Premium ${this.months} mois - ${this.twitchName}`
                            }]
                        });
                    } catch (error) {
                        notifications.add({
                            type: 'error',
                            message: 'Erreur lors de la création de la commande. Veuillez réessayer.',
                            timeout: 8000
                        });
                        console.error('Erreur createOrder:', error);
                    }
                },
                onApprove: async (data, actions) => {
                    try {
                        notifications.add({
                            type: 'info',
                            message: 'Traitement du paiement en cours...',
                            timeout: 3000
                        });
                        
                        // Capturer le paiement PayPal
                        const details = await actions.order.capture();

                        //console.log('Payment details:', details);
                        
                        // Activer l'abonnement premium via l'API
                        const activationResult = await subscriptionService.createSubscription({
                            twitchName: this.twitchName,
                            months: this.months,
                            donorName: this.donorName,
                            paymentDetails: {
                                orderId: details.id,
                                payerEmail: details.payer.email_address,
                                payerName: `${details.payer.name.given_name} ${details.payer.name.surname}`,
                                amount: details.purchase_units[0].payments.captures[0].amount.value,
                                currency: details.purchase_units[0].payments.captures[0].amount.currency_code,
                                status: details.status,
                                createTime: details.create_time,
                                updateTime: details.update_time
                            }
                        });

                        //console.log('Activation result:', activationResult);
                        //console.log('Success:', activationResult.data?.success);
                        //console.log('Data:', activationResult.data?.data);
                        //console.log('IsActive:', activationResult.data?.data?.isActive);

                        // Vérifier le succès et isActive dans la bonne structure
                        if (activationResult.data?.success && activationResult.data?.data?.isActive) {
                            // Notification de succès
                            notifications.add({
                                type: 'success',
                                message: `Paiement réussi ! L'abonnement premium a été activé pour la chaîne ${this.twitchName}.`,
                                timeout: 8000
                            });
                            
                            // Émettre l'événement de succès pour le composant parent
                            this.$emit('payment-success', {
                                orderId: details.purchase_units[0].payments.captures[0].id,
                                twitchName: this.twitchName,
                                months: this.months,
                                expirationDate: activationResult.data.data.expirationDate
                            });
                        } else {
                            throw new Error('Activation failed');
                        }
                    } catch (error) {
                        console.error('Payment/Activation error:', error);
                        
                        // Si l'erreur vient de PayPal, afficher une notification
                        if (error.name === 'PAYER_ACTION_REQUIRED') {
                            notifications.add({
                                type: 'error',
                                message: 'Une action supplémentaire est requise pour finaliser le paiement.',
                                timeout: 8000
                            });
                        }
                        // Pour les autres erreurs, ne pas afficher de notification ici
                        // car l'intercepteur API l'a déjà fait
                        
                        this.$emit('payment-error', error);
                    }
                },
                onError: (err) => {
                    // Log l'erreur pour le debugging
                    console.error('PayPal Error:', err);
                    
                    // Émettre l'événement d'erreur pour le composant parent
                    this.$emit('payment-error', err);
                    
                    // Afficher une notification plus spécifique selon le type d'erreur
                    if (err.name === 'INVALID_REQUEST') {
                        notifications.add({
                            type: 'error',
                            message: 'La requête de paiement est invalide. Veuillez réessayer.',
                            timeout: 8000
                        });
                    } else if (err.name === 'NETWORK_ERROR') {
                        notifications.add({
                            type: 'error',
                            message: 'Erreur de connexion avec PayPal. Veuillez vérifier votre connexion internet.',
                            timeout: 8000
                        });
                    } else {
                        notifications.add({
                            type: 'error',
                            message: 'Une erreur est survenue lors du paiement. Veuillez réessayer.',
                            timeout: 8000
                        });
                    }
                }
            }).render('#paypal-button-container');
        }
    }
};
</script>

<style scoped>
h2 {
    margin-bottom: 25px;
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.9);
}

#paypal-button-container {
    margin-top: 20px;
}

#result-message {
    margin-top: 15px;
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.8);
}
</style>
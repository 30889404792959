import axios from 'axios';
import { notifications } from '../store/notifications';

// Fonction utilitaire pour gérer la déconnexion
const handleLogout = () => {
    localStorage.removeItem('discord_token');
    localStorage.removeItem('discord_user');
    localStorage.removeItem('subscription_info');
    localStorage.removeItem('impersonated_user');
    window.location.href = '/';
};

// Liste des endpoints qui ne nécessitent pas d'authentification
const publicEndpoints = [
    '/subscriptions/health'
];

// API instance qui sera exportée
const api = axios.create({
    // En production, utiliser l'URL complète de l'API, sinon utiliser le proxy
    baseURL: process.env.NODE_ENV === 'production' 
        ? process.env.VUE_APP_API_URL 
        : '/api',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Request interceptor
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('discord_token');
        // Only add token if endpoint is not public
        if (token && !publicEndpoints.includes(config.url)) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Response interceptor
api.interceptors.response.use(
    response => response,
    error => {
        // Ne pas traiter les erreurs si on est déjà sur la page de connexion
        if (window.location.pathname === '/') {
            return Promise.reject(error);
        }

        if (error.response) {
            // Gérer les erreurs d'authentification
            if (error.response.status === 401) {
                // Ne pas rediriger pour les endpoints publics ou /subscriptions
                if (!publicEndpoints.includes(error.config.url) && !error.config.url.includes('/subscriptions')) {
                    handleLogout();
                }
                return Promise.reject(error);
            }

            // Gérer les autres erreurs avec un code de réponse
            notifications.add({
                type: 'error',
                message: error.response.data?.message || 'Une erreur est survenue',
                timeout: 5000
            });
        } else if (error.request) {
            // La requête a été faite mais aucune réponse n'a été reçue
            notifications.add({
                type: 'error',
                message: 'Impossible de contacter le serveur',
                timeout: 5000
            });
        } else {
            // Une erreur s'est produite lors de la configuration de la requête
            notifications.add({
                type: 'error',
                message: 'Erreur lors de la configuration de la requête',
                timeout: 5000
            });
        }

        return Promise.reject(error);
    }
);

export { api };

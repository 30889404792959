import { reactive } from 'vue'

export const notifications = reactive({
  items: [],
  add(notification) {
    const id = Date.now()
    this.items.push({
      id,
      ...notification
    })
    
    if (notification.timeout !== 0) {
      setTimeout(() => {
        this.remove(id)
      }, notification.timeout || 5000)
    }
    
    return id
  },
  remove(id) {
    const index = this.items.findIndex(item => item.id === id)
    if (index > -1) {
      this.items.splice(index, 1)
    }
  },
  clear() {
    this.items = []
  }
})

import { api } from './api';

export const subscriptionService = {
    async getSubscriptionStatus(impersonateId = null) {
        // Add impersonateId as a query parameter if provided
        const url = impersonateId ? `/subscriptions?impersonateId=${impersonateId}` : '/subscriptions';
        const response = await api.get(url);
        return response;
    },

    async verifyTwitchName(twitchName) {
        const response = await api.post('/subscriptions/verify-twitch', { twitchName });
        return response;
    },

    async createSubscription(data) {
        const {
            twitchName,
            months,
            donorName,
            paymentDetails
        } = data;

        const payload = {
            twitchName,
            months,
            donorName,
            payment: {
                orderId: paymentDetails.orderId,
                payerEmail: paymentDetails.payerEmail,
                payerName: paymentDetails.payerName,
                amount: paymentDetails.amount,
                currency: paymentDetails.currency,
                status: paymentDetails.status,
                create_time: paymentDetails.createTime,
                update_time: paymentDetails.updateTime
            }
        };

        const response = await api.post('/subscriptions/activate', payload);
        return response;
    },

    async getSubscriptionHistory() {
        const response = await api.get('/subscriptions/history');
        return response;
    },

    async cancelSubscription(subscriptionId) {
        const response = await api.post(`/subscriptions/${subscriptionId}/cancel`);
        return response;
    },

    async extendSubscription(subscriptionId, months) {
        const response = await api.post(`/subscriptions/${subscriptionId}/extend`, { months });
        return response;
    },

    async transferSubscription(subscriptionId, newTwitchName) {
        const response = await api.post(`/subscriptions/${subscriptionId}/transfer`, { newTwitchName });
        return response;
    }
};

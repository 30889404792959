<template>
    <div class="container">
        <div class="title-section">
            <div class="title-content">
                <h2>Configuration Modération</h2>
                <p class="description">Configurez les paramètres de modération pour votre chaîne</p>
            </div>
            <div class="title-controls">
                <div class="toggle-container">
                    <label class="toggle">
                        <input type="checkbox" v-model="isActive" @change="handleModuleToggle">
                        <span class="slider"></span>
                    </label>
                    <span class="toggle-label" :class="{ 'active': isActive }">{{ isActive ? 'Activé' : 'Désactivé' }}</span>
                </div>
                <div class="title-icon">
                    🛡️
                </div>
            </div>
        </div>
        
        <div class="mod-reminder" v-if="isActive">
            <div class="reminder-content">
                <span class="reminder-icon">⚠️</span>
                <div class="reminder-text">
                    <p><strong>Attention:</strong> Pour que la modération fonctionne correctement, <strong class="bot-name">Buddi_Bot</strong> doit être modérateur sur votre chaîne Twitch.</p>
                </div>
            </div>
        </div>
        
        <div class="beta-reminder" v-if="isActive">
            <div class="reminder-content">
                <span class="reminder-icon">🧪</span>
                <div class="reminder-text">
                    <p><strong>Note:</strong> Le module de modération est actuellement en <strong class="beta-text">version bêta</strong>. Certaines fonctionnalités peuvent être instables ou sujettes à modification.</p>
                </div>
            </div>
        </div>
        
        <div v-if="isActive">
            <div class="form-section">
                <h3>Configuration du salon</h3>
                <p class="section-description">Choisissez le salon Discord où seront envoyés les logs de modération</p>
                <div class="channel-selector">
                    <div class="radio-group">
                        <label class="radio-label">
                            <input type="radio" v-model="channelType" value="default">
                            <span>Utiliser le salon général</span>
                        </label>
                        <label class="radio-label">
                            <input type="radio" v-model="channelType" value="custom">
                            <span>Utiliser un salon personnalisé</span>
                        </label>
                    </div>
                    <div v-if="channelType === 'custom'" class="custom-channel">
                        <input 
                            type="text" 
                            v-model="modLogChannelId" 
                            placeholder="ID du salon Discord"
                        >
                    </div>
                </div>
            </div>

            <div class="form-section">
                <h3>Paramètres Anti-Spam</h3>
                <p class="section-description">Configurez les règles de détection du spam et les punitions associées</p>
                <div class="spam-settings">
                    <div class="input-container">
                        <label>Messages identiques maximum</label>
                        <input 
                            type="number" 
                            v-model="spamSettings.MaxIdenticalMessages" 
                            min="0" 
                            class="number-input"
                        >
                        <p class="helper-text">Nombre maximum de messages identiques autorisés (0 = désactivé)</p>
                    </div>

                    <div class="input-container">
                        <label>Pourcentage maximum de majuscules</label>
                        <input 
                            type="number" 
                            v-model="spamSettings.MaxCapsPercentage" 
                            min="0" 
                            max="100" 
                            class="number-input"
                        >
                        <p class="helper-text">Pourcentage maximum de caractères en majuscules par message (0 = désactivé)</p>
                    </div>
                </div>
            </div>

            <div class="form-section premium-section">
                <div class="section-header">
                    <div class="header-title">
                        <h3>Options Avancées</h3>
                        <span class="premium-badge">
                            <span class="premium-icon">⭐</span>
                            Premium
                        </span>
                        <span class="beta-badge">
                            <span class="beta-icon">🧪</span>
                            Beta
                        </span>
                    </div>
                </div>

                <div class="premium-content">
                    <div v-if="isPremium || isVIP" class="banned-words">
                        <h4>Mots bannis</h4>
                        <div v-for="(word, index) in bannedWords" :key="index" class="banned-word-item" :class="{ expanded: word.isExpanded }">
                            <div class="banned-word-header">
                                <div class="main-inputs">
                                    <div class="input-with-button">
                                        <input type="text" 
                                            v-model="word.word" 
                                            placeholder="Mot à bannir" 
                                            class="text-input"
                                            @blur="word.isExpanded = word.word.length > 0">
                                    </div>
                                    <div class="select-container compact">
                                        <select v-model="word.punishment" class="select-input">
                                            <option value="0">Avertissement</option>
                                            <option value="1">Timeout</option>
                                            <option value="2">Bannissement</option>
                                            <option value="3">Suppression</option>
                                            <option value="4">Progressive</option>
                                        </select>
                                    </div>
                                    <button @click="word.isExpanded = !word.isExpanded" class="expand-button">
                                        {{ word.isExpanded ? '▼' : '▶' }}
                                    </button>
                                    <button @click="removeBannedWord(index)" class="remove-button">×</button>
                                </div>
                            </div>

                            <div v-show="word.isExpanded" class="banned-word-details">
                                <div v-if="word.punishment === '4'" class="helper-text">
                                    Système progressif :<br>
                                    1. Premier message : Avertissement verbal<br>
                                    2. Après l'avertissement : Timeout 10 minutes<br>
                                    3. Infractions suivantes : Timeout 12 heures<br>
                                    <em>Les sanctions sont réinitialisées après 24h sans infraction</em>
                                </div>

                                <div v-if="word.punishment === '1'" class="duration-container">
                                    <label>Durée (secondes)</label>
                                    <input 
                                        type="number" 
                                        v-model="word.duration" 
                                        min="1"
                                        class="number-input"
                                    >
                                </div>

                                <div class="options-container">
                                    <div class="toggle-container">
                                        <label class="toggle">
                                            <input type="checkbox" v-model="word.ignoreCase">
                                            <span class="slider"></span>
                                        </label>
                                        <span class="toggle-label" :class="{ 'active': word.ignoreCase }">Ignorer la casse</span>
                                    </div>
                                    <p class="helper-text">Ne pas tenir compte des majuscules/minuscules lors de la détection</p>
                                </div>

                                <div class="variations-section">
                                    <div class="variations-header">
                                        <label>Variations</label>
                                        <button @click="addVariation(index)" class="action-button">
                                            Ajouter une variation
                                        </button>
                                    </div>
                                    <div class="variations-list">
                                        <div v-for="(variation, vIndex) in word.variations" :key="vIndex" class="input-with-button">
                                            <input type="text" v-model="word.variations[vIndex]" placeholder="Variation du mot" class="text-input">
                                            <button @click="removeVariation(index, vIndex)" class="remove-button">×</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button @click="addBannedWord" class="add-item-button">
                            <span class="add-icon">+</span>
                            Ajouter un mot banni
                        </button>
                    </div>
                    
                    <div v-if="!isPremium && !isVIP" class="premium-locked-content">
                        <p>Les fonctionnalités avancées de modération sont réservées aux utilisateurs Premium ou VIP</p>
                        <router-link to="/premium" class="upgrade-button">Passer à la version Premium</router-link>
                    </div>
                </div>
            </div>

            <div class="form-section premium-section">
                <div class="section-header">
                    <div class="header-title">
                        <h3>Liste blanche</h3>
                        <span class="premium-badge">
                            <span class="premium-icon">⭐</span>
                            Premium
                        </span>
                    </div>
                </div>
                <p class="section-description">Les utilisateurs dans cette liste ne seront pas affectés par la modération</p>
                
                <div class="tags-input-container">
                    <div class="tags-list">
                        <span v-for="(user, index) in whitelistedUsers" :key="index" class="tag">
                            {{ user }}
                            <button @click="removeWhitelistedUser(index)" class="remove-tag">&times;</button>
                        </span>
                    </div>
                    <input
                        type="text"
                        v-model="newWhitelistedUser"
                        @keydown.enter.prevent="addWhitelistedUser"
                        placeholder="Entrez un nom d'utilisateur"
                        class="tag-input"
                    >
                </div>
            </div>

            <div class="actions-section">
                <button @click="saveChanges" class="save-button" :disabled="isSaving">
                    {{ isSaving ? 'Enregistrement...' : 'Enregistrer les modifications' }}
                </button>
            </div>
        </div>
        <div v-else class="module-disabled">
            <p>Activez le module pour accéder aux paramètres de configuration</p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useSubscriptionData } from '../composables/useSubscriptionData'
import { notifications } from '../store/notifications'
import { api } from '../services/api'
import { logger } from '../utils/logger'

export default {
    name: 'Moderation',
    setup() {
        const { subscriptionData, error } = useSubscriptionData()
        
        // Constantes pour les types de punition
        const PunishmentType = {
            Warning: 0,
            Timeout: 1,
            Ban: 2,
            DeleteOnly: 3,
            Progressive: 4
        }

        // États réactifs
        const isActive = ref(false)
        const isPremium = ref(false)
        const isVIP = ref(false)
        const channelType = ref('default')
        const modLogChannelId = ref('')
        const spamSettings = ref({
            MaxIdenticalMessages: 0,
            MaxCapsPercentage: 0
        })
        const bannedWords = ref([])
        const whitelistedUsers = ref([])
        const newWhitelistedUser = ref('')
        const isSaving = ref(false)

        const updateLocalData = (data) => {
            logger.log('Updating Moderation data with:', data)
            
            try {
                // Parse les données JSON si nécessaire
                const parsedData = typeof data === 'string' ? JSON.parse(data) : data
                
                // Chercher le module moderation (ModuleType: 104) dans BotModules
                const moderationData = parsedData?.BotModules?.find(m => m.ModuleType === 104)
                
                if (!moderationData) {
                    logger.warn('No moderation module data found')
                    return
                }

                isActive.value = moderationData.IsActive || false
                isPremium.value = parsedData?.PremiumStatus === 1 || parsedData?.IsVIP === true
                isVIP.value = parsedData?.IsVIP === true

                // Configuration du salon de logs
                if (moderationData.ModLogChannelId) {
                    channelType.value = 'custom'
                    modLogChannelId.value = moderationData.ModLogChannelId
                } else {
                    channelType.value = 'default'
                    modLogChannelId.value = ''
                }

                // Paramètres anti-spam
                if (moderationData.SpamSettings) {
                    spamSettings.value = {
                        MaxIdenticalMessages: moderationData.SpamSettings.MaxIdenticalMessages !== undefined ? moderationData.SpamSettings.MaxIdenticalMessages : 0,
                        MaxCapsPercentage: moderationData.SpamSettings.MaxCapsPercentage !== undefined ? moderationData.SpamSettings.MaxCapsPercentage : 0
                    }
                }

                // Mots bannis
                if (moderationData.BannedWords) {
                    bannedWords.value = moderationData.BannedWords.map(word => ({
                        word: word.Word,
                        punishment: word.Punishment,  // Stocké comme nombre
                        duration: word.Duration,
                        ignoreCase: word.IgnoreCase,
                        variations: word.Variations || [],
                        isExpanded: false
                    }))
                }

                // Liste blanche
                if (moderationData.WhitelistedUsers) {
                    whitelistedUsers.value = moderationData.WhitelistedUsers
                }

                logger.log('Updated local data:', {
                    bannedWords: bannedWords.value,
                    whitelistedUsers: whitelistedUsers.value,
                    spamSettings: spamSettings.value
                })
            } catch (error) {
                logger.error('Error parsing moderation data:', error)
            }
        }

        // Fonction pour convertir les données avant l'envoi au backend
        const prepareBannedWordsForBackend = (words) => {
            return words.map(word => ({
                Word: word.word,
                Punishment: word.punishment !== undefined ? parseInt(word.punishment) : PunishmentType.Warning, // Valeur par défaut
                Duration: word.duration || 0,
                IgnoreCase: word.ignoreCase !== false,
                Variations: word.variations || []
            }))
        }

        const handleModuleToggle = async () => {
            isSaving.value = true
            try {
                const response = await api.post('/subscriptions/modules', {
                    IsActive: isActive.value,
                    ModLogChannelId: channelType.value === 'custom' ? modLogChannelId.value : null,
                    ModuleType: 104,
                    SpamSettings: {
                        MaxIdenticalMessages: parseInt(spamSettings.value.MaxIdenticalMessages),
                        MaxCapsPercentage: parseInt(spamSettings.value.MaxCapsPercentage)
                    },
                    BannedWords: prepareBannedWordsForBackend(bannedWords.value),
                    WhitelistedUsers: whitelistedUsers.value
                })

                if (response.data.success) {
                    notifications.add({
                        type: 'success',
                        message: `Module ${isActive.value ? 'activé' : 'désactivé'}`,
                        timeout: 5000
                    })
                }
            } catch (error) {
                logger.error('Error toggling module:', error)
                isActive.value = !isActive.value // Revert the change
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la mise à jour du module',
                    timeout: 5000
                })
            } finally {
                isSaving.value = false
            }
        }

        const saveChanges = async () => {
            isSaving.value = true
            try {
                const response = await api.post('/subscriptions/modules', {
                    IsActive: isActive.value,
                    ModLogChannelId: channelType.value === 'custom' ? modLogChannelId.value : null,
                    ModuleType: 104,
                    SpamSettings: {
                        MaxIdenticalMessages: parseInt(spamSettings.value.MaxIdenticalMessages),
                        MaxCapsPercentage: parseInt(spamSettings.value.MaxCapsPercentage)
                    },
                    BannedWords: prepareBannedWordsForBackend(bannedWords.value),
                    WhitelistedUsers: whitelistedUsers.value
                })

                if (response.data.success) {
                    notifications.add({
                        type: 'success',
                        message: 'Configuration sauvegardée',
                        timeout: 5000
                    })
                }
            } catch (error) {
                logger.error('Error saving changes:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la sauvegarde',
                    timeout: 5000
                })
            } finally {
                isSaving.value = false
            }
        }

        // Charger les données au montage du composant
        onMounted(async () => {
            // Essayer de charger depuis le cache d'abord
            const cachedData = localStorage.getItem('subscription_info')
            if (cachedData) {
                try {
                    updateLocalData(cachedData)
                } catch (e) {
                    logger.error('Error parsing cached data:', e)
                }
            }
        })

        // Surveiller les changements dans les données d'abonnement
        watch(subscriptionData, (newData) => {
            if (newData?.data) {
                updateLocalData(newData.data)
            }
        }, { immediate: true })

        // Surveiller les erreurs
        watch(error, (newError) => {
            if (newError) {
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du chargement des données: ' + newError,
                    timeout: 5000
                })
            }
        })

        const addBannedWord = () => {
            bannedWords.value.push({
                word: '',
                punishment: PunishmentType.Warning,  // Valeur par défaut
                duration: 0,
                ignoreCase: true,
                variations: [],
                isExpanded: true
            })
        }

        const removeBannedWord = (index) => {
            bannedWords.value.splice(index, 1)
        }

        const addVariation = (wordIndex) => {
            bannedWords.value[wordIndex].variations.push('')
        }

        const removeVariation = (wordIndex, variationIndex) => {
            bannedWords.value[wordIndex].variations.splice(variationIndex, 1)
        }

        const addWhitelistedUser = () => {
            const username = newWhitelistedUser.value.trim().toLowerCase()
            if (username && !whitelistedUsers.value.includes(username)) {
                whitelistedUsers.value.push(username)
                newWhitelistedUser.value = ''
            }
        }

        const removeWhitelistedUser = (index) => {
            whitelistedUsers.value.splice(index, 1)
        }

        // Exposer les fonctions et variables nécessaires
        return {
            isActive,
            isPremium,
            isVIP,
            channelType,
            modLogChannelId,
            spamSettings,
            bannedWords,
            whitelistedUsers,
            newWhitelistedUser,
            isSaving,
            handleModuleToggle,
            saveChanges,
            addBannedWord,
            removeBannedWord,
            addVariation,
            removeVariation,
            addWhitelistedUser,
            removeWhitelistedUser
        }
    }
}
</script>

<style scoped>
.container {
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
}

.title-section {
    background: #1A202C;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #2D3748;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title-content h2 {
    color: white;
    margin: 0 0 0.5rem 0;
    font-size: 1.8rem;
    font-weight: 600;
}

.title-content .description {
    color: #A0AEC0;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
}

.title-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 200px;
}

.form-section {
    background: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-section.premium-section {
    border: 1px solid rgba(255, 215, 0, 0.2);
    background: linear-gradient(to right bottom, rgba(42, 42, 42, 1), rgba(42, 42, 42, 0.95));
}

.form-section h3 {
    color: white;
    margin: 0 0 1rem 0;
    font-size: 1.3rem;
    font-weight: 600;
}

.section-description {
    color: #A0AEC0;
    margin: 0.5rem 0 1.5rem 0;
    font-size: 1rem;
    line-height: 1.5;
}

.module-disabled {
    text-align: center;
    padding: 2rem;
    color: #A0AEC0;
    background: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 12px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.badge {
    background: #2D3748;
    color: #A0AEC0;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.9rem;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    flex-shrink: 0;
}

.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2D3748;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #004B55;
}

input:checked + .slider:before {
    transform: translateX(24px);
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 140px;
}

.toggle-label {
    color: #A0AEC0;
}

.toggle-label.active {
    color: #E2E8F0;
}

.actions-section {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
}

.save-button {
    background-color: #004B55;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.save-button:hover {
    background-color: #00596B;
}

.save-button:disabled {
    background-color: #2D3748;
    cursor: not-allowed;
    opacity: 0.7;
}

.input-container {
    margin-bottom: 1.5rem;
}

.input-container label {
    display: block;
    color: #A0AEC0;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.number-input, .select-input {
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    padding: 0.75rem;
    color: white;
    width: 100%;
    max-width: 300px;
    font-size: 1rem;
}

.number-input:focus, .select-input:focus {
    outline: none;
    border-color: #004B55;
    box-shadow: 0 0 0 1px #004B55;
}

.helper-text {
    font-size: 0.85rem;
    color: #A0AEC0;
    margin-top: 0.5rem;
    line-height: 1.4;
}

.helper-text em {
    color: #718096;
    font-style: italic;
    font-size: 0.8rem;
}

.options-container {
    margin-bottom: 1.5rem;
}

.options-container .toggle-container {
    margin-bottom: 0.5rem;
}

.punishment-settings {
    border-top: 1px solid #4A5568;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
}

.punishment-settings h4 {
    color: white;
    font-size: 1.1rem;
    margin: 0 0 1rem 0;
}

.spam-settings {
    margin-top: 1rem;
}

.text-input {
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    padding: 0.75rem;
    color: white;
    width: 100%;
    font-size: 1rem;
}

.text-input:focus {
    outline: none;
    border-color: #004B55;
    box-shadow: 0 0 0 1px #004B55;
}

.input-with-button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.input-with-button .text-input {
    flex: 1;
}

.banned-words {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.banned-word-item {
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    transition: all 0.3s ease;
}

.banned-word-header {
    width: 100%;
}

.main-inputs {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
}

.input-with-button {
    flex: 2;
    min-width: 0;
}

.select-container.compact {
    flex: 1;
    min-width: 150px;
}

.expand-button, .remove-button {
    flex-shrink: 0;
}

.banned-word-item.expanded {
    padding: 0.75rem;
}

.banned-word-details {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #4A5568;
}

.punishment-group {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
}

.select-container, .duration-container {
    flex: 1;
}

.variations-section {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #4A5568;
}

.variations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.variations-header label {
    color: white;
    font-size: 1rem;
    font-weight: 500;
}

.variations-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.action-button {
    background: #4A5568;
    color: #E2E8F0;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.action-button:hover {
    background: #2D3748;
}

.remove-button {
    background: #4A5568;
    color: #E2E8F0;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.remove-button:hover {
    background: #E53E3E;
}

.add-item-button {
    background: #2D3748;
    border: 1px dashed #4A5568;
    color: #A0AEC0;
    padding: 0.75rem;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.3s ease;
}

.add-item-button:hover {
    background: #4A5568;
    color: #E2E8F0;
}

.add-icon {
    font-size: 1.2rem;
    font-weight: bold;
}

.tags-input-container {
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 0.5rem;
    background: var(--input-bg);
    min-height: 44px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.tag {
    background: var(--primary-color);
    color: var(--text-light);
    border-radius: 20px;
    padding: 0.35rem 0.75rem;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}

.tag:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.remove-tag {
    background: none;
    border: none;
    color: var(--text-light);
    font-size: 1.2rem;
    padding: 0;
    margin-left: 0.25rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: all 0.2s ease;
    opacity: 0.8;
}

.remove-tag:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.2);
}

.tag-input {
    border: none;
    outline: none;
    flex: 1;
    min-width: 120px;
    font-size: 0.875rem;
    padding: 0.5rem;
    background: transparent;
    color: var(--text-color);
}

.tag-input::placeholder {
    color: var(--text-muted);
}

.whitelist-section {
    background: var(--card-bg);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}

.whitelist-section h4 {
    color: var(--text-color);
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.section-description {
    color: var(--text-muted);
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.premium-locked-content {
    padding: 1.5rem;
    margin-top: 1rem;
    text-align: center;
    color: #A0AEC0;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 215, 0, 0.2);
    border-radius: 8px;
}

.upgrade-button {
    display: inline-block;
    background: linear-gradient(135deg, #FFD700, #FFA500);
    color: #1a1a1a;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    margin-top: 1rem;
    transition: transform 0.2s ease;
}

.upgrade-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.beta-text {
    color: #61E3FF;
    font-weight: 600;
}

.beta-badge {
    display: inline-flex;
    align-items: center;
    background: linear-gradient(135deg, #61E3FF, #6161FF);
    color: #1a1a1a;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    margin-left: 0.75rem;
}

.beta-icon {
    margin-right: 0.25rem;
}

.reminder-content .bot-name {
    color: #FFC107;
    font-weight: 600;
}

.mod-reminder {
    margin: 0 0 1.5rem 0;
    padding: 0.85rem 1.2rem;
    background: #1A202C;
    border: 1px solid #2D3748;
    border-left: 4px solid #FFC107;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reminder-content {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
}

.reminder-text {
    flex: 1;
}

.reminder-content p {
    margin: 0;
    color: #E2E8F0;
    font-size: 0.95rem;
    line-height: 1.5;
}

.reminder-content strong {
    color: #E2E8F0;
    font-weight: 600;
}

.reminder-content .bot-name {
    color: #FFC107;
    font-weight: 600;
}

.reminder-icon {
    font-size: 1.3rem;
    flex-shrink: 0;
    margin-top: 0.1rem;
}

.channel-selector {
    margin-top: 1rem;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1rem;
}

.radio-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    cursor: pointer;
}

.radio-label input[type="radio"] {
    margin: 0;
    cursor: pointer;
}

.custom-channel {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
}

.custom-channel input {
    background: #1A202C;
    border: 1px solid #4A5568;
    border-radius: 8px;
    padding: 0.75rem;
    color: #E2E8F0;
    width: 100%;
    max-width: 300px;
}

.custom-channel input:focus {
    outline: none;
    border-color: #004B55;
    box-shadow: 0 0 0 1px #004B55;
}

.text-input {
    background: #1A202C;
    border: 1px solid #4A5568;
    border-radius: 8px;
    padding: 0.75rem;
    color: #E2E8F0;
    width: 100%;
    font-size: 1rem;
}

.text-input::placeholder {
    color: #718096;
}

.select-input {
    background: #1A202C;
    border: 1px solid #4A5568;
    border-radius: 8px;
    padding: 0.75rem;
    color: #E2E8F0;
    width: 100%;
    font-size: 1rem;
}

.select-input option {
    background: #2D3748;
    color: #E2E8F0;
}

.number-input {
    background: #1A202C;
    border: 1px solid #4A5568;
    border-radius: 8px;
    padding: 0.75rem;
    color: #E2E8F0;
    width: 100%;
    font-size: 1rem;
}

label {
    color: #E2E8F0;
}

.toggle-label {
    color: #A0AEC0;
}

.toggle-label.active {
    color: #E2E8F0;
}

.helper-text {
    font-size: 0.85rem;
    color: #A0AEC0;
    margin-top: 0.5rem;
    line-height: 1.4;
}

.helper-text em {
    color: #718096;
    font-style: italic;
    font-size: 0.8rem;
}

.expand-button {
    background: none;
    border: none;
    color: #E2E8F0;
    cursor: pointer;
    padding: 0.25rem;
    font-size: 0.8rem;
    transition: all 0.3s ease;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expand-button:hover {
    color: #00C9C9;
}

.beta-reminder {
    margin-bottom: 1.5rem;
    background: rgba(97, 97, 255, 0.1);
    border: 1px solid rgba(97, 97, 255, 0.3);
    border-radius: 8px;
    padding: 0.75rem 1rem;
}

.premium-badge {
    display: inline-flex;
    align-items: center;
    background: linear-gradient(135deg, #FFD700, #FFA500);
    color: #1a1a1a;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.premium-icon {
    margin-right: 0.4rem;
    font-size: 0.9rem;
}

.header-title {
    display: flex;
    align-items: center;
}
</style>

<template>
    <div class="container">
        <div class="title-section">
            <div class="title-content">
                <h2>Intelligence Artificielle</h2>
                <p class="description">Configurez l'assistant IA pour votre chaîne Twitch</p>
            </div>
            <div class="title-controls">
                <div class="toggle-container">
                    <label class="toggle">
                        <input type="checkbox" v-model="isActive" @change="handleModuleToggle">
                        <span class="slider"></span>
                    </label>
                    <span class="toggle-label" :class="{ 'active': isActive }">{{ isActive ? 'Activé' : 'Désactivé' }}</span>
                </div>
                <div class="title-icon">
                    🤖
                </div>
            </div>
        </div>
        
        <div class="mod-reminder" v-if="isActive">
            <div class="reminder-content">
                <span class="reminder-icon">⚠️</span>
                <div class="reminder-text">
                    <p><strong>Attention:</strong> Le module Intelligence Artificielle n'est disponible qu'avec un <strong class="premium-text">abonnement Premium</strong>. {{ isPremium ? 'Votre abonnement Premium est actif.' : 'Veuillez souscrire à un abonnement Premium pour utiliser cette fonctionnalité.' }}</p>
                </div>
            </div>
        </div>
        
        <div v-if="isActive">
            <div class="form-section" v-if="isPremium">
                <h3>Configuration de l'IA</h3>
                <p class="section-description">Configurez les instructions et informations pour votre assistant IA</p>
                
                <div class="instructions-section">
                    <h4>Instructions <span class="help-icon" @mouseenter="showInstructionsHelp = true" @mouseleave="showInstructionsHelp = false">ℹ️
                        <div class="tooltip-content" v-if="showInstructionsHelp">
                            <h5>Exemple d'instructions</h5>
                            <pre>Tu es un assistant pour ma chaîne Twitch. Réponds de manière concise et amicale aux questions sur ma chaîne. Utilise toujours des emojis. Ne donne jamais des informations confidentielles. Si tu ne connais pas la réponse, dis simplement que tu ne sais pas.</pre>
                        </div>
                    </span></h4>
                    <p class="subsection-description">Définissez comment l'IA doit se comporter et répondre à vos spectateurs</p>
                    <div class="textarea-container">
                        <textarea 
                            v-model="instructions" 
                            placeholder="Entrez les instructions pour l'IA (ex: 'Réponds toujours poliment', 'Utilise des emojis', etc.)"
                            rows="8"
                            class="text-area"
                        ></textarea>
                    </div>
                </div>

                <div class="informations-section">
                    <h4>Informations <span class="help-icon" @mouseenter="showInformationsHelp = true" @mouseleave="showInformationsHelp = false">ℹ️
                        <div class="tooltip-content" v-if="showInformationsHelp">
                            <h5>Exemple d'informations</h5>
                            <pre>Nom: DoZzI
Jeux: GTA RP sur le serveur NewLife
Horaires: Lundi, Mercredi, Vendredi 20h-23h
Règles du chat: 
- Pas de spoilers
- Respecter les autres spectateurs
- Ne pas parler des autres streamers sans raison
Discord: discord.gg/buddidozzi</pre>
                        </div>
                    </span></h4>
                    <p class="subsection-description">Fournissez à l'IA des informations sur votre chaîne, vos jeux préférés, vos règles, etc.</p>
                    <div class="textarea-container">
                        <textarea 
                            v-model="informations" 
                            placeholder="Entrez des informations sur votre chaîne (ex: 'Je joue principalement à GTA RP', 'Les règles de mon chat sont...', etc.)"
                            rows="8"
                            class="text-area"
                        ></textarea>
                    </div>
                </div>

                <div class="sponsorship-section">
                    <h4>Commandes de Sponsoring <span class="help-icon" @mouseenter="showSponsorHelp = true" @mouseleave="showSponsorHelp = false">ℹ️
                        <div class="tooltip-content" v-if="showSponsorHelp">
                            <h5>Exemple de brief publicitaire</h5>
                            <pre>Nom: MonEnergyDrink
Commande: !energy
Brief: MonEnergyDrink est une boisson énergisante au goût fruité qui donne de l'énergie pendant 5 heures sans crash. Mentionne qu'elle est faite avec des ingrédients naturels et qu'elle est parfaite pour les sessions de gaming. Utilise un ton enthousiaste et jeune. Termine par le slogan "MonEnergyDrink - Restez dans la partie !"</pre>
                        </div>
                    </span></h4>
                    <p class="subsection-description">Créez des commandes personnalisées pour que l'IA génère des messages publicitaires intelligents</p>
                    
                    <div class="sponsor-commands-list">
                        <div v-if="sponsorCommands.length === 0" class="no-sponsors">
                            <p>Aucune commande de sponsoring définie. Ajoutez-en une ci-dessous.</p>
                        </div>
                        
                        <div v-for="(command, index) in sponsorCommands" :key="index" class="sponsor-command-card">
                            <div class="sponsor-header">
                                <h5>{{ command.name }}</h5>
                                <div class="sponsor-controls">
                                    <button class="control-button edit" @click="editSponsorCommand(index)">✏️</button>
                                    <button class="control-button delete" @click="deleteSponsorCommand(index)">🗑️</button>
                                    <div class="toggle-mini">
                                        <label class="toggle-small">
                                            <input type="checkbox" v-model="command.isActive">
                                            <span class="slider-small"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="sponsor-details">
                                <div class="sponsor-field">
                                    <span class="field-label">Commande:</span>
                                    <span class="field-value">!{{ command.command }}</span>
                                </div>
                                <div class="sponsor-field">
                                    <span class="field-label">Brief:</span>
                                    <span class="field-value brief">{{ command.brief }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="add-sponsor-section" v-if="!showSponsorForm">
                        <button @click="showSponsorForm = true" class="add-sponsor-button">
                            + Ajouter une commande de sponsoring
                        </button>
                    </div>
                    
                    <div class="sponsor-form" v-if="showSponsorForm">
                        <h5>{{ editingSponsorIndex === -1 ? 'Nouvelle commande de sponsoring' : 'Modifier la commande' }}</h5>
                        <div class="form-row">
                            <label>Nom du sponsor</label>
                            <input type="text" v-model="newSponsor.name" placeholder="ex: MonEnergyDrink" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Commande (sans le !)</label>
                            <input type="text" v-model="newSponsor.command" placeholder="ex: sponsor" class="form-input" />
                        </div>
                        <div class="form-row">
                            <label>Brief publicitaire</label>
                            <textarea 
                                v-model="newSponsor.brief" 
                                placeholder="Décrivez le produit et comment l'IA devrait en faire la promotion (ton, style, points clés à mentionner, etc.)"
                                rows="4"
                                class="form-textarea"
                            ></textarea>
                        </div>
                        <div class="form-actions">
                            <button @click="cancelSponsorForm" class="cancel-button">Annuler</button>
                            <button @click="saveSponsorCommand" class="save-sponsor-button" :disabled="!isSponsorFormValid">
                                {{ editingSponsorIndex === -1 ? 'Ajouter' : 'Mettre à jour' }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="actions-section">
                    <button @click="saveChanges" class="save-button" :disabled="isSaving">
                        {{ isSaving ? 'Enregistrement...' : 'Enregistrer les modifications' }}
                    </button>
                </div>
            </div>
            <div v-else class="premium-required">
                <div class="premium-message">
                    <div class="premium-icon">✨</div>
                    <h3>Fonctionnalité Premium</h3>
                    <p>Le module Intelligence Artificielle est disponible uniquement avec un abonnement Premium.</p>
                    <router-link to="/premium" class="premium-button">Obtenir Premium</router-link>
                </div>
            </div>
        </div>

        <div v-else class="module-disabled">
            <p>Activez le module pour accéder aux paramètres de configuration</p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useSubscriptionData } from '../composables/useSubscriptionData'
import { notifications } from '../store/notifications'
import { api } from '../services/api'
import { logger } from '../utils/logger'

export default {
    name: 'IntelligenceArtificielle',
    setup() {
        const { subscriptionData, error } = useSubscriptionData()
        
        // États réactifs
        const isActive = ref(false)
        const isPremium = ref(false)
        const isVIP = ref(false)
        const instructions = ref('')
        const informations = ref('')
        const isSaving = ref(false)
        const sponsorCommands = ref([])
        const showSponsorForm = ref(false)
        const editingSponsorIndex = ref(-1)
        const newSponsor = ref({
            name: '',
            command: '',
            brief: ''
        })
        
        // États pour les tooltips d'aide
        const showInstructionsHelp = ref(false)
        const showInformationsHelp = ref(false)
        const showSponsorHelp = ref(false)

        const updateLocalData = (data) => {
            logger.log('Updating IA data with:', data)
            
            try {
                // Parse les données JSON si nécessaire
                const parsedData = typeof data === 'string' ? JSON.parse(data) : data
                
                // Chercher le module IA (ModuleType: 103) dans BotModules
                const iaData = parsedData?.BotModules?.find(m => m.ModuleType === 103)
                
                if (!iaData) {
                    logger.warn('No IA module data found')
                    return
                }

                isActive.value = iaData.IsActive || false
                isPremium.value = parsedData?.PremiumStatus === 1 || parsedData?.IsVIP === true
                isVIP.value = parsedData?.IsVIP === true

                // Configuration de l'IA
                instructions.value = iaData.Instructions || ''
                informations.value = iaData.Informations || ''

                // Commandes de sponsoring
                sponsorCommands.value = iaData.SponsorCommands || []

                logger.log('Updated local IA data:', {
                    isActive: isActive.value,
                    instructions: instructions.value,
                    informations: informations.value,
                    sponsorCommands: sponsorCommands.value
                })
            } catch (error) {
                logger.error('Error parsing IA data:', error)
            }
        }

        const handleModuleToggle = async () => {
            isSaving.value = true
            try {
                const response = await api.post('/subscriptions/modules', {
                    IsActive: isActive.value,
                    ModuleType: 103, // TYPE_IA
                    Instructions: instructions.value,
                    Informations: informations.value,
                    SponsorCommands: sponsorCommands.value
                })

                if (response.data.success) {
                    notifications.add({
                        type: 'success',
                        message: `Module ${isActive.value ? 'activé' : 'désactivé'}`,
                        timeout: 5000
                    })
                }
            } catch (error) {
                logger.error('Error toggling module:', error)
                isActive.value = !isActive.value // Revert the change
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la mise à jour du module',
                    timeout: 5000
                })
            } finally {
                isSaving.value = false
            }
        }

        const saveChanges = async () => {
            isSaving.value = true
            try {
                const response = await api.post('/subscriptions/modules', {
                    IsActive: isActive.value,
                    ModuleType: 103, // TYPE_IA
                    Instructions: instructions.value,
                    Informations: informations.value,
                    SponsorCommands: sponsorCommands.value
                })

                if (response.data.success) {
                    notifications.add({
                        type: 'success',
                        message: 'Configuration sauvegardée',
                        timeout: 5000
                    })
                }
            } catch (error) {
                logger.error('Error saving changes:', error)
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors de la sauvegarde',
                    timeout: 5000
                })
            } finally {
                isSaving.value = false
            }
        }

        const editSponsorCommand = (index) => {
            editingSponsorIndex.value = index
            newSponsor.value = { ...sponsorCommands.value[index] }
            showSponsorForm.value = true
        }

        const deleteSponsorCommand = (index) => {
            sponsorCommands.value.splice(index, 1)
        }

        const cancelSponsorForm = () => {
            showSponsorForm.value = false
            editingSponsorIndex.value = -1
            newSponsor.value = {
                name: '',
                command: '',
                brief: ''
            }
        }

        const saveSponsorCommand = () => {
            if (editingSponsorIndex.value === -1) {
                sponsorCommands.value.push({ ...newSponsor.value })
            } else {
                sponsorCommands.value[editingSponsorIndex.value] = { ...newSponsor.value }
            }
            cancelSponsorForm()
        }

        const isSponsorFormValid = () => {
            return newSponsor.value.name !== '' && newSponsor.value.command !== '' && newSponsor.value.brief !== ''
        }

        // Charger les données au montage du composant
        onMounted(async () => {
            // Essayer de charger depuis le cache d'abord
            const cachedData = localStorage.getItem('subscription_info')
            if (cachedData) {
                try {
                    updateLocalData(cachedData)
                } catch (e) {
                    logger.error('Error parsing cached data:', e)
                }
            }
        })

        // Surveiller les changements dans les données d'abonnement
        watch(subscriptionData, (newData) => {
            if (newData?.data) {
                updateLocalData(newData.data)
            }
        }, { immediate: true })

        // Surveiller les erreurs
        watch(error, (newError) => {
            if (newError) {
                notifications.add({
                    type: 'error',
                    message: 'Erreur lors du chargement des données: ' + newError,
                    timeout: 5000
                })
            }
        })

        // Exposer les fonctions et variables nécessaires
        return {
            isActive,
            isPremium,
            isVIP,
            instructions,
            informations,
            isSaving,
            sponsorCommands,
            showSponsorForm,
            editingSponsorIndex,
            newSponsor,
            showInstructionsHelp,
            showInformationsHelp,
            showSponsorHelp,
            handleModuleToggle,
            saveChanges,
            editSponsorCommand,
            deleteSponsorCommand,
            cancelSponsorForm,
            saveSponsorCommand,
            isSponsorFormValid
        }
    }
}
</script>

<style scoped>
.container {
    padding: 2rem;
    max-width: 900px;
    margin: 0 auto;
}

.title-section {
    background: #1A202C;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #2D3748;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title-content h2 {
    color: white;
    margin: 0 0 0.5rem 0;
    font-size: 1.8rem;
    font-weight: 600;
}

.title-content .description {
    color: #A0AEC0;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
}

.title-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 200px;
}

.form-section {
    background: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-section h3 {
    color: white;
    margin: 0 0 1rem 0;
    font-size: 1.3rem;
    font-weight: 600;
}

.section-description {
    color: #A0AEC0;
    margin: 0.5rem 0 1.5rem 0;
    font-size: 1rem;
    line-height: 1.5;
}

.subsection-description {
    color: #A0AEC0;
    margin: 0.3rem 0 1rem 0;
    font-size: 0.9rem;
    line-height: 1.4;
}

.instructions-section, .informations-section {
    margin-bottom: 2rem;
}

.instructions-section h4, .informations-section h4, .examples-section h4 {
    color: white;
    font-size: 1.1rem;
    margin: 0 0 0.5rem 0;
}

.textarea-container {
    width: 100%;
}

.text-area {
    width: 100%;
    padding: 1rem;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    color: white;
    font-size: 0.95rem;
    line-height: 1.5;
    resize: vertical;
    min-height: 150px;
}

.text-area:focus {
    outline: none;
    border-color: #63B3ED;
    box-shadow: 0 0 0 2px rgba(99, 179, 237, 0.3);
}

.help-icon {
    display: inline-block;
    position: relative;
    cursor: help;
    margin-left: 8px;
    font-size: 1rem;
}

.tooltip-content {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    padding: 1rem;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 100;
    margin-top: 10px;
}

.tooltip-content::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #4A5568;
}

.tooltip-content h5 {
    color: white;
    font-size: 1rem;
    margin: 0 0 0.8rem 0;
}

.tooltip-content pre {
    color: #A0AEC0;
    font-family: 'Courier New', monospace;
    font-size: 0.85rem;
    line-height: 1.4;
    white-space: pre-wrap;
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
}

.module-disabled {
    text-align: center;
    padding: 2rem;
    color: #A0AEC0;
    background: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 12px;
}

.actions-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.save-button {
    background: linear-gradient(135deg, #4299E1, #3182CE);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 4px 6px rgba(49, 130, 206, 0.3);
}

.save-button:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 6px 8px rgba(49, 130, 206, 0.4);
}

.save-button:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(49, 130, 206, 0.3);
}

.save-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    flex-shrink: 0;
}

.toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2D3748;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #63B3ED;
}

input:focus + .slider {
    box-shadow: 0 0 1px #63B3ED;
}

input:checked + .slider:before {
    transform: translateX(24px);
}

.toggle-label {
    color: #A0AEC0;
    font-size: 1rem;
    margin-left: 0.5rem;
}

.toggle-label.active {
    color: #63B3ED;
}

.mod-reminder {
    margin: 0 0 1.5rem 0;
    padding: 0.85rem 1.2rem;
    background: #1A202C;
    border: 1px solid #2D3748;
    border-left: 4px solid #FFC107;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reminder-content {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
}

.reminder-text {
    flex: 1;
}

.reminder-content p {
    margin: 0;
    color: #E2E8F0;
    font-size: 0.95rem;
    line-height: 1.5;
}

.reminder-content strong {
    color: #E2E8F0;
    font-weight: 600;
}

.reminder-content .bot-name {
    color: #FFC107;
    font-weight: 600;
}

.reminder-content .premium-text {
    color: #F6E05E;
    font-weight: 600;
}

.reminder-icon {
    font-size: 1.3rem;
    flex-shrink: 0;
    margin-top: 0.1rem;
}

.sponsorship-section {
    margin-bottom: 2rem;
}

.sponsorship-section h4 {
    color: white;
    font-size: 1.1rem;
    margin: 0 0 0.5rem 0;
}

.sponsor-commands-list {
    margin-top: 1rem;
}

.no-sponsors {
    text-align: center;
    padding: 1rem;
    color: #A0AEC0;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
}

.sponsor-command-card {
    background: #2D3748;
    border-radius: 8px;
    padding: 1rem;
    border: 1px solid #4A5568;
    margin-bottom: 1rem;
}

.sponsor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.sponsor-header h5 {
    color: white;
    font-size: 1rem;
    margin: 0;
}

.sponsor-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.control-button {
    background: none;
    border: none;
    padding: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.control-button.edit {
    color: #63B3ED;
}

.control-button.delete {
    color: #FFC107;
}

.toggle-mini {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
    flex-shrink: 0;
    margin-left: 0.5rem;
}

.toggle-small input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider-small {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2D3748;
    transition: .4s;
    border-radius: 34px;
}

.slider-small:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider-small {
    background-color: #63B3ED;
}

input:focus + .slider-small {
    box-shadow: 0 0 1px #63B3ED;
}

input:checked + .slider-small:before {
    transform: translateX(14px);
}

.sponsor-details {
    margin-top: 0.5rem;
}

.sponsor-field {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.field-label {
    color: #A0AEC0;
    font-size: 0.9rem;
}

.field-value {
    color: white;
    font-size: 0.9rem;
}

.brief {
    white-space: pre-wrap;
}

.add-sponsor-section {
    text-align: center;
    margin-top: 1rem;
}

.add-sponsor-button {
    background: linear-gradient(135deg, #4299E1, #3182CE);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 4px 6px rgba(49, 130, 206, 0.3);
}

.add-sponsor-button:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 6px 8px rgba(49, 130, 206, 0.4);
}

.add-sponsor-button:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(49, 130, 206, 0.3);
}

.add-sponsor-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.sponsor-form {
    margin-top: 1rem;
    padding: 1rem;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
}

.sponsor-form h5 {
    color: white;
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
}

.form-row {
    margin-bottom: 1rem;
}

.form-row label {
    color: #A0AEC0;
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
}

.form-input {
    width: 100%;
    padding: 0.5rem;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    color: white;
    font-size: 0.9rem;
}

.form-textarea {
    width: 100%;
    padding: 0.5rem;
    background: #2D3748;
    border: 1px solid #4A5568;
    border-radius: 8px;
    color: white;
    font-size: 0.9rem;
    resize: vertical;
    min-height: 100px;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 1rem;
}

.cancel-button {
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    color: #A0AEC0;
}

.cancel-button:hover {
    color: white;
}

.save-sponsor-button {
    background: linear-gradient(135deg, #4299E1, #3182CE);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 4px 6px rgba(49, 130, 206, 0.3);
}

.save-sponsor-button:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 6px 8px rgba(49, 130, 206, 0.4);
}

.save-sponsor-button:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(49, 130, 206, 0.3);
}

.save-sponsor-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.premium-required {
    text-align: center;
    padding: 3rem 2rem;
    color: #E2E8F0;
    background: #1A202C;
    border: 1px solid #2D3748;
    border-radius: 12px;
    margin-bottom: 1.5rem;
}

.premium-message {
    max-width: 500px;
    margin: 0 auto;
}

.premium-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #F6E05E;
}

.premium-message h3 {
    color: #F6E05E;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.premium-message p {
    color: #A0AEC0;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.5;
}

.premium-button {
    display: inline-block;
    background: linear-gradient(135deg, #F6E05E, #DD6B20);
    color: #1A202C;
    font-weight: 600;
    padding: 0.75rem 2rem;
    border-radius: 8px;
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(246, 224, 94, 0.3);
}

.premium-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(246, 224, 94, 0.4);
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }
    
    .examples-list {
        grid-template-columns: 1fr;
    }
    
    .title-section {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }
    
    .title-controls {
        width: 100%;
        justify-content: space-between;
    }
}
</style>

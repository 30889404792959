const isDevelopment = process.env.NODE_ENV === 'development'

export const logger = {
    log: () => {
        if (isDevelopment) {
            //console.log()
        }
    },
    warn: () => {
        if (isDevelopment) {
            //console.warn()
        }
    },
    error: () => {
        // Always log errors, even in production, as they are important for debugging
        //console.error()
    }
}

import axios from 'axios';
import { api } from './api';

// Instance d'API spécifique pour Discord
const discordApi = axios.create({
    baseURL: 'https://discord.com/api/v10',
    headers: {
        'Content-Type': 'application/json'
    }
});

// Ajouter l'intercepteur pour le token Discord
discordApi.interceptors.request.use(
    config => {
        const token = localStorage.getItem('discord_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

export const authService = {
    async getUserInfo() {
        try {
            console.log('Fetching Discord user info...');
            const token = localStorage.getItem('discord_token');
            console.log('Discord token exists:', !!token);
            
            // Utiliser l'instance Discord API
            const response = await discordApi.get('/users/@me');
            console.log('Discord API response:', response.data);
            
            if (response && response.data) {
                // Stocker les données utilisateur dans le localStorage
                localStorage.setItem('discord_user', JSON.stringify(response.data));
                return response.data;
            }
            throw new Error('Invalid response from Discord API');
        } catch (error) {
            console.error('Error fetching user info:', error);
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            
            // Si on a des données en cache, on les utilise
            const cachedUser = localStorage.getItem('discord_user');
            console.log('Using cached user data:', !!cachedUser);
            if (cachedUser) {
                return JSON.parse(cachedUser);
            }
            throw error;
        }
    },

    async getSubscriptionInfo() {
        try {
            const response = await api.get('/subscriptions');
            if (response && response.data) {
                localStorage.setItem('subscription_info', JSON.stringify(response.data));
                return response.data;
            }
            throw new Error('Invalid response from subscription API');
        } catch (error) {
            console.error('Error fetching subscription info:', error);
            const cachedInfo = localStorage.getItem('subscription_info');
            if (cachedInfo) {
                return JSON.parse(cachedInfo);
            }
            throw error;
        }
    },

    async logout() {
        try {
            // Appeler l'API pour invalider le token
            await api.post('/subscriptions/logout');
        } catch (error) {
            console.error('Error during logout:', error);
        } finally {
            // Clear all stored data
            localStorage.removeItem('discord_token');
            localStorage.removeItem('discord_user');
            localStorage.removeItem('subscription_info');
            localStorage.removeItem('impersonated_user');
            
            // Redirect to home page
            window.location.href = '/';
        }
    }
};

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles/global.css'
import './assets/styles/premium.css'
import { api } from './services/api'

const app = createApp(App)

// Ajouter l'instance API comme propriété globale
app.config.globalProperties.$axios = api

// Log environment info
//console.log('Environment:', process.env.NODE_ENV);
//console.log('API URL:', process.env.VUE_APP_API_URL);

app.use(router).mount('#app')